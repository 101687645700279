import Box from "@mui/material/Box";
import React, { useEffect, useMemo, useState } from "react";
// import * as Btn from "../../Buttons";

//import material-ui
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import ButtonTable from "../../Buttons/buttonTable";
import styles from "./TableHavestHavested.module.css";

import { TableSortLabel } from "@mui/material";
import { HarvestService } from "../../../service/harvest_service";
import { UserService } from "../../../service/user_service";
import Loading from "../../Loading";
import { captalizeFirstLetterEacheWord } from "../../../utils/utils";

function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowRight />
				) : (
					<KeyboardArrowLeft />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowLeft />
				) : (
					<KeyboardArrowRight />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
	const {  order, orderBy,  onRequestSort,columns} =
    props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead className={styles.tableGenerator}>			
			<TableRow>
				{columns.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={ "left"}
						padding={ "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell></TableCell>
				<TableCell></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

function HarvestHarvested(props) {
	const [page, setPage] = useState(0); //page tabela
	const [rowsPerPage, setRowsPerPage] = useState(7); //rows per page
	
	const [selected, setSelected] = useState([]);
	const [order, setOrder] = useState("asc");
	const [columnsHarvestHarvested, setColumnsHarvestHarvested] = useState([
		{
			id: "cuture",
			label: "Cultura",
		},
		{
			id: "quant",
			label: "Quantidade",
		},
		{
			id: "unid",
			label: "Unidade de Medida",
		},
		{
			id: "lote",
			label: "Lote",
		},
		{
			id: "data_solicitacao",
			label: "Data de solicitação",
		},
		{
			id: "data_realizacao",
			label: "Data de realização",
		},		
	]);
	const [orderBy, setOrderBy] = useState(columnsHarvestHarvested[0].id);
	const [isLoading, setIsLoading] = useState(true);
	const [harvestData, setHarvestData] = useState([]);
	const [totalHarvestData, setTotalHarvestData] = useState(0);

	const userService = new UserService();
	const harvestService = new HarvestService();

	useEffect(() => {
		updateHarvestData();
	}, [rowsPerPage, page, order, orderBy]);

	const updateHarvestData = async () => {
		setIsLoading(true);
		const user = JSON.parse(localStorage.getItem("user"));
		const propertyId = localStorage.getItem("propertyId");
		let orderByValue =  JSON.stringify(
			{
				createdAt: "desc"
			}
		);

		if(orderBy === columnsHarvestHarvested[0].id){
			orderByValue =  JSON.stringify(
				[
					{
						propriedadeCultivo: {
							cultivo: {
								cultura: order
							}
						}
					},
					{
						propriedadeCultivo: {
							cultivo: {
								variedade: order
							}
						}
					}
				]
			);
		} else if(orderBy === columnsHarvestHarvested[1].id){
			orderByValue =  JSON.stringify(
				{
					quantidade: order
				}
			);
		} else if(orderBy === columnsHarvestHarvested[2].id){
			orderByValue =  JSON.stringify(
				{
					embalagem: {
						unidadeMedida: {
							sigla: order
						}
					}
				}
			);
		} else if(orderBy === columnsHarvestHarvested[3].id){
			orderByValue =  JSON.stringify(
				{
					codigo: order
				}
			);
		} else if(orderBy === columnsHarvestHarvested[4].id){
			orderByValue =  JSON.stringify(
				{
					dataColheita: order
				}
			);
		} else if(orderBy === columnsHarvestHarvested[5].id){
			orderByValue =  JSON.stringify(
				{
					dataColheita: order
				}
			);
		} else if(orderBy === columnsHarvestHarvested[6].id){
			orderByValue =  JSON.stringify(
				{
					usuarioResponsavel: {
						nome: order
					}
				}
			);
		}

		let finishedParams = {
			page: page + 1,
			limit: rowsPerPage,
			sortBy: orderByValue
		};

		if (user.isAdmin) {
			finishedParams = {
				...finishedParams,
				filterValue: encodeURI(JSON.stringify({pendente: false, previsao: false})),
			};
		} else {
			finishedParams = {
				...finishedParams,
				filterValue: encodeURI(JSON.stringify({pendente: false, previsao: false, responsavelColheita: user.id})),
			};
		}
		const finished = await harvestService.getByProperty(propertyId, finishedParams);

		setTotalHarvestData(finished.totalItems);
		setHarvestData(await getColheitasRealizadasDetails(finished.harvests, user.isAdmin));

		setIsLoading(false);
	};

	const getColheitasRealizadasDetails = async (lotes, userAdmin) => {
		const lotesDetails = [];
		for (let i = 0; i < lotes.length; i++) {
			const lote = lotes[i];
			const safrasFromLote = lote.loteSafras;
			let quantidade = 0;
			for (let j = 0; j < safrasFromLote.length; j++) {
				const safra = safrasFromLote[j];
				quantidade += safra.quantidade ? safra.quantidade * 1000 : 0;
			}
			quantidade /= 1000;
			const Propertycultivo = lote.propriedadeCultivo;
			const embalagem = lote.embalagem;
			const unidade = embalagem.unidadeMedida;
			const usuario = lote.responsavelColheita !== null && lote.responsavelColheita !== undefined ? await userService.getById(lote.responsavelColheita) : null;
			const cultivo = Propertycultivo.cultivo;
			let newColheita = {
				id: lote.id,
				itemId: lote.id,
				cuture: captalizeFirstLetterEacheWord(`${cultivo.cultura} ${(cultivo.variedade ?? "")}`),
				quant: quantidade,
				unid: unidade.sigla,
				id_cuture: lote.propriedadecultivoID,
				lote: lote.codigo,
				operador: usuario !== null && usuario !== undefined? usuario.nome : "Não informado",
				data_solicitacao: formatDate(lote.dataColheita),
				data_realizacao: formatDate(lote.dataColheita),
			};
			lotesDetails.push(newColheita);
		}

		if (userAdmin) {
			setColumnsHarvestHarvested([
				{
					id: "cuture",
					label: "Cultura",
				},
				{
					id: "quant",
					label: "Quantidade",
				},
				{
					id: "unid",
					label: "Unidade de Medida",
				},
				{
					id: "lote",
					label: "Lote",
				},
				{
					id: "data_solicitacao",
					label: "Data de solicitação",
				},
				{
					id: "data_realizacao",
					label: "Data de realização",
				},
				{
					id: "operador",
					label: "Operador",
				},
			]);
		} else {
			setColumnsHarvestHarvested([
				{
					id: "cuture",
					label: "Cultura",
				},
				{
					id: "quant",
					label: "Quantidade",
				},
				{
					id: "unid",
					label: "Unidade de Medida",
				},
				{
					id: "lote",
					label: "Lote",
				},
				{
					id: "data_solicitacao",
					label: "Data de solicitação",
				},
				{
					id: "data_realizacao",
					label: "Data de realização",
				},		
			]);
		}
		return lotesDetails;
	};

	const formatDate = (dateToFormat) => {
		const date = new Date(dateToFormat);
		return (
			("0" + date.getUTCDate()).slice(-2) +
			"/" +
			("0" + (date.getUTCMonth() + 1)).slice(-2) +
			"/" +
			date.getUTCFullYear()
		);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClicks = (event) => {
		if (event.target.checked) {
			const newSelected = dataFilter.map((n) => n.name);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage); //seta a pagina atual tabela
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value); //seta a quantidade de linhas por pagina
		setPage(0); //seta a pagina atual tabela
	};

	const dataFilter = harvestData.filter(
		(item) => item.cuture.toLowerCase().includes(props.search.toLowerCase()) //filtra os dados pelo nome do cliente
		|| item.quant.toString().includes(props.search.toLowerCase()) 
		|| item.unid.toLowerCase().includes(props.search.toLowerCase()) 
		|| item.data_solicitacao.toString().includes(props.search.toLowerCase()) 
		|| item.data_realizacao.toString().includes(props.search.toLowerCase()) 
		|| item.operador.toLowerCase().includes(props.search.toLowerCase()) 
		|| item.lote.toLowerCase().includes(props.search.toLowerCase()) 
	);
	const getFormatDate = (date) => {
		let data = date.split("/");
		return `${data[2]}-${data[1]}-${data[0]}`;
	};
	function descendingComparator(a, b, orderBy) {
		if (orderBy === "data_solicitacao") {
			const dateA = new Date(getFormatDate(a[orderBy]));
			const dateB = new Date(getFormatDate(b[orderBy]));
			if (dateB < dateA) return -1;
			if (dateB > dateA) return 1;
			return 0;
		}
		if (orderBy === "data_realizacao") {
			const dateA = new Date(getFormatDate(a[orderBy]));
			const dateB = new Date(getFormatDate(b[orderBy]));
			if (dateB < dateA) return -1;
			if (dateB > dateA) return 1;
			return 0;
		}

		if (b[orderBy] < a[orderBy]) 
			return -1;
  
		if (b[orderBy] > a[orderBy]) 
			return 1;
  
		return 0;
	}
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) 
				return order;
    
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}
	function getComparator(order, orderBy) {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}
	const visibleRows = useMemo(
		() =>
			stableSort(dataFilter, getComparator(order, orderBy)),
		[order, orderBy, page, rowsPerPage,dataFilter]
	);

	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<div>
				<Paper>
					<TableContainer>
						<Table stickyHeader>
							<EnhancedTableHead
								className={styles.tableGenerator}
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onSelectAllClick={handleSelectAllClicks}
								onRequestSort={handleRequestSort}
								rowCount={dataFilter.length}
								columns={columnsHarvestHarvested}
							/>
							{/* <TableHead className={styles.tableGenerator}>
								<TableRow>
									{
										//cria as colunas da tabela
										columns.map((column) => (
											<TableCell key={column.id}>{column}</TableCell>
										))
									}
									<TableCell></TableCell>
									<TableCell></TableCell>
								</TableRow>
							</TableHead> */}
							<TableBody>
								{
									//cria as linhas da tabela
									visibleRows
										.map((row) => {
											return (
												<TableRow
													hover
													tabIndex={-1}
													key={row.id}
													sx={{ height: 3 }}
												>
													{columnsHarvestHarvested.map((column) => {
														//seta as colunas
														return (
															<TableCell
																id={row.id_cuture}
																// onClick={handleOpen}
																key={column}
															>
																{row[column.id]}
															</TableCell>
														);
													})}
													<TableCell>
														{/* <Btn.GenericButtonGreen
															text="Colher"
															className="harvestButton"
														/> */}
													</TableCell>

													<TableCell>
														<ButtonTable
															className={styles.btn}
															editHref={`/colheita/cadastroColheita?id=${row.id}`}
															deletFunc={() => props.handleDelete(row.id)}
															deleteMessage="Deseja deletar? (Todos os pedidos e perdas relacionados também serão excluídos)"
														/>
													</TableCell>
												</TableRow>
											);
										})
								}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[7, 20, 30]}
						component="div"
						colSpan={7}
						count={totalHarvestData}
						rowsPerPage={rowsPerPage}
						page={page}
						labelRowsPerPage="Linhas por pagina"
						SelectProps={{
							inputProps: {
								"aria-label": "rows per page",
							},
							native: false,
						}}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						ActionsComponent={TablePaginationActions}
					/>
				</Paper>
			</div>
		);
}

export default HarvestHarvested;
