/* eslint-disable react/prop-types */
import React, { useMemo, useState } from "react";
//import material-ui
// import { LocalOffer as LabelIcon } from "@mui/icons-material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import ButtonTable from "../Buttons/buttonTable";
//import css
// import "./style.css";
import { Box, TableSortLabel } from "@mui/material";
import styles from "./TableModalPedidos.module.css";
// import { Link } from "react-router-dom";

// const style = {
// 	position: "absolute",
// 	top: "50%",
// 	left: "50%",
// 	width: "100%",
// 	transform: "translate(-50%, -50%)",
// };

function TablePaginationActions(props) {
	const theme = useTheme(); //pega o tema do material-ui
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0); //primeira pagina
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1); //pagina anterior
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1); //proxima pagina
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1)); //ultima pagina
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};
function EnhancedTableHead(props) {
	const {  order, orderBy,  onRequestSort,columns} =
    props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};
	return (
		<TableHead className={styles.tableModal}>			
			<TableRow>
				{columns.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={ "left"}
						padding={ "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
					
				))}
				<TableCell></TableCell>
				<TableCell></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

function TableModal(props) {
	const data = props.data; //pega os dados do componente pai
	const keys = props.columns; //array de chaves
	// const [openModal, setOpenModal] = useState(false);
	// const [tamanho, setTamanho] = useState("GerarQrcodeEtiqueta6x4");
	// const [codeBar, setCodeBar] = useState("0");
	// const [id, setId] = useState("");
	// const [idPedido, setIdPedido] = useState("");
	// const [distribuidor, setDistribuidor] = useState(false);
	// const [doubleTag, setDoubleTag] = useState(false);
	const [selected, setSelected] = useState([]);
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState(keys[0].id);
	const [page, setPage] = React.useState(0); //paginação
	const [rowsPerPage, setRowsPerPage] = React.useState(7); //paginação
	
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClicks = (event) => {
		if (event.target.checked) {
			const newSelected = data.map((n) => n.name);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const dataFilter = data.filter((item) => {
		//filtra os dados
		if (item.nPedido.toString() === props.numberPedido) return item;
	});

	const handleChangePage = (event, newPage) => {
		setPage(newPage); //seta a pagina
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value); //seta as linhas por pagina
		setPage(0);
	};
	// const handleOpenMOdal = (rowId, rowPedidoId) => {
	// 	setId(rowId);
	// 	setIdPedido(rowPedidoId);
	// 	setOpenModal(true); //abre o modal
	// };
	// const handleCloseData = () => {
	// 	setOpenModal(false); //fecha o modal
	// };
	// function goTo() {
	// 	let type1 = "EAN13";
	// 	let type2 = "CODE128";
	// 	if (codeBar === "EAN13") {
	// 		return (window.location.href = `/pdfQrcode/?pag=${tamanho}&index=${
	// 			props.keys
	// 		}&item=${id}&pedido=${idPedido}${
	// 			distribuidor ? `&checkDist=${0}` : ""
	// 		}&codBar2Type=${type1}${
	// 			doubleTag ? `&doubleTag=${0}` : ""
	// 		}`);
	// 	} else if (codeBar === "CODE128") {
	// 		return (window.location.href = `/pdfQrcode/?pag=${tamanho}&index=${
	// 			props.keys
	// 		}&item=${id}&pedido=${idPedido}${
	// 			distribuidor ? `&checkDist=${0}` : ""
	// 		}&codBar1Type=${type2}${
	// 			doubleTag ? `&doubleTag=${0}` : ""
	// 		}`);
	// 	} else if (codeBar === "all") {
	// 		return (window.location.href = `/pdfQrcode/?pag=${tamanho}&index=${
	// 			props.keys
	// 		}&item=${id}&pedido=${idPedido}${
	// 			distribuidor ? `&checkDist=${0}` : ""
	// 		}&codBar2Type=${type1}&codBar1Type=${type2}${
	// 			doubleTag ? `&doubleTag=${0}` : ""
	// 		}`);
	// 	} else if (codeBar === "0") {
	// 		return (window.location.href = `/pdfQrcode/?pag=${tamanho}&index=${
	// 			props.keys
	// 		}&item=${id}&pedido=${idPedido}${distribuidor ? `&checkDist=${0}` : ""}${
	// 			doubleTag ? `&doubleTag=${0}` : ""
	// 		}`);
	// 	}
	// }
	const getFormatDate = (date) => {
		let data = date.split("/");
		return `${data[2]}-${data[1]}-${data[0]}`;
	};
	function descendingComparator(a, b, orderBy) {
		if (orderBy === "dataEmbalagem") {
			const dateA = new Date(getFormatDate(a[orderBy]));
			const dateB = new Date(getFormatDate(b[orderBy]));
			if (dateB < dateA) return -1;
			if (dateB > dateA) return 1;
			return 0;
		}

		if (b[orderBy] < a[orderBy]) 
			return -1;
  
		if (b[orderBy] > a[orderBy]) 
			return 1;
  
		return 0;
	}
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) 
				return order;
    
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}
	function getComparator(order, orderBy) {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}
	const visibleRows = useMemo(
		() =>
			stableSort(dataFilter, getComparator(order, orderBy)),
		[order, orderBy, page, rowsPerPage, dataFilter]
	);

	return (
		<>
			<Paper sx={{ width: "100%" }}>
				<TableContainer sx={{ maxHeight: "75vh" }}>
					<Table stickyHeader>
						<EnhancedTableHead
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClicks}
							onRequestSort={handleRequestSort}
							rowCount={dataFilter.length}
							columns={keys}
						/>
						<TableBody>
							{
								//cria as linhas
								visibleRows
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row) => {
										return (
											/*gera linha */
											<TableRow
												className={styles.trModal}
												hover
												tabIndex={-1}
												key={row.id}
												sx={{ height: 3 }}
											>
												{keys.map((column) => {
													return (
														/* gera celula*/
														<TableCell className={styles.tdModal} key={column}>
															{row[column.id]}
														</TableCell>
													);
												})}
												{/*local do botao */}
												<TableCell className={styles.tdModal}>
													{/* <Link
														to={
															"/pdf/?pag=GerarQrcodeEtiqueta&index=" +
															props.keys +
															"&item=" +
															row.id+&
														}
														className={styles.labelButton}
														id={props.keys}
													> */}
													{/* <button
														className={styles.labelButton}
														onClick={() => handleOpenMOdal(row.loteID, row.id)}
													>
														<LabelIcon />
														Gerar etiqueta
													</button> */}
													{/* </Link> */}
												</TableCell>
												<TableCell>
													{props.menuTable === "disable" ? null : (
														<ButtonTable
															className={styles.btn}
															editHref={
																props.hrefEdit !== undefined
																	? `${props.hrefEdit}&orderItemId=${row.id}&loteId=${row.loteID}`
																	: `/editarCadastro?${row.id}`
															} //editHref={props.editHref row.id}
															deletFunc={() => props.handleDelete(row.id)}
															rowId={row.id}
															loteID={row.loteID}
															optionSettings={props.optionSettings}
															custonOpition={row.devolvido !== "Sim" ? props.custonOpition : null} // determina quais opções irao aparecer
														/>
													)}
												</TableCell>
											</TableRow>
										);
									})
							}
						</TableBody>
					</Table>
				</TableContainer>
				{/*paginação da tabela*/}
				<TablePagination
					className={styles.tablePagination}
					rowsPerPageOptions={[7, 14, 21, { label: "Todos", value: Number.MAX_VALUE }]}
					component="div"
					colSpan={7}
					count={dataFilter.length}
					rowsPerPage={rowsPerPage}
					page={page}
					labelRowsPerPage="Linhas por pagina"
					SelectProps={{
						inputProps: {
							"aria-label": "rows per page",
						},
						native: false,
					}}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					ActionsComponent={TablePaginationActions}
				/>
			</Paper>
			{/* <Modal
				open={openModal}
				onClose={handleCloseData}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box
					sx={{
						...style,
						width: { xs: "90%", sm: "50%", md: "50%" },
						bgcolor: "white",
						borderRadius: 4,
						p: 3,
						m: 0,
					}}
				>
					<Grid container rowSpacing={3} columnSpacing={3} direction={"column"}>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<p className={styles.registrationTitle}>Gerar Etiqueta</p>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{
								display: "flex",
								justifyContent: "start",
								alignItems: "center",
							}}
						>
							<p>
								<b>Tamanho</b>
							</p>
						</Grid>

						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
							className={styles.registrationInputModal}
						>
							<select
								id="lote"
								onChange={(event) => setTamanho(event.target.value)}
								value={tamanho}
							>
								<option value={"GerarQrcodeEtiqueta6x4"}>Etiqueta 6cm x 4cm</option>
								<option value={"GerarQrcodeEtiqueta6x4Rot"}>Etiqueta 6cm x 4cm Rotacionada</option>
								<option value={"GerarQrcodeEtiqueta6x8"}>Etiqueta 6cm x 8cm</option>
								<option value={"GerarQrcodeEtiqueta10x10"}>Etiqueta 10cm x 10cm</option>
								<option value={"GerarQrcodeEtiqueta9x3"}>Etiqueta 9cm x 3cm</option>
								<option value={"GerarQrcodeEtiqueta12x5"}>Etiqueta 12cm x 5cm</option>
								<option value={"GerarQrcodeEtiqueta16x5"}>Etiqueta 16cm x 5cm</option>
							</select>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{
								display: "flex",
								justifyContent: "start",
								alignItems: "center",
							}}
						>
							<p>
								<b>Quantidade de codigos de barras</b>
							</p>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
							className={styles.registrationInputModal}
						>
							<select
								id="lote"
								onChange={(event) => setCodeBar(event.target.value)}
								value={codeBar}
							>
								{(tamanho === "GerarQrcodeEtiqueta6x4" ||
									tamanho === "GerarQrcodeEtiqueta6x4Rot" ||
									tamanho === "GerarQrcodeEtiqueta6x8" ||
									tamanho === "GerarQrcodeEtiqueta10x10" ||
									tamanho === "GerarQrcodeEtiqueta12x5" ||
									tamanho === "GerarQrcodeEtiqueta9x3") && (
									<>
										<option value={"0"}>Nenhum</option>
										<option value={"EAN13"}>código de barras do produtor</option>
										<option value={"CODE128"}>código de barras do cliente</option>
									</>
								)}
								{tamanho === "GerarQrcodeEtiqueta16x5" && (
									<>
										<option value={"0"}>Nenhum</option>
										<option value={"EAN13"}>código de barras do produtor</option>
										<option value={"CODE128"}>código de barras do cliente</option>
										<option value={"all"}>código de barras do produtor e do cliente</option>
									</>
								)}
							</select>
						</Grid>

						<Grid
							item
							xs={12}
							sm={12}
							md={6}
							sx={{
								display: "flex",
								justifyContent: "start",
								alignItems: "center",
							}}
						>
							<input
								type="checkbox"
								value={distribuidor}
								onChange={() => setDistribuidor(!distribuidor)}
							/>
							<p>
								<b> Informação da distribuidora</b>
							</p>
						</Grid>

						<Grid
							item
							xs={12}
							sm={12}
							md={6}
							sx={{
								display: "flex",
								justifyContent: "start",
								alignItems: "center",
							}}
						>
							<input
								type="checkbox"
								value={doubleTag}
								onChange={() => setDoubleTag(!doubleTag)}
							/>
							<p>
								<b> Etiqueta dupla</b>
							</p>
						</Grid>

						<Grid item xs={12} sm={12} md={12} sx={{ marginTop: 5 }}>
							<Grid
								container
								rowSpacing={{ xs: 2, sm: 1, md: 0 }}
								columnSpacing={{ xs: 1, sm: 2, md: 3 }}
								display="flex"
								alignItems="center"
								justifyContent="end"
								flexDirection={{
									xs: "column-reverse",
									sm: "column-reverse",
									md: "row",
								}}
							>
								<Grid item xs={12} sm={12} md={3}>
									<button
										type="button"
										onClick={handleCloseData}
										className={styles.registrationButtonCancelar}
									>
										Cancelar
									</button>
								</Grid>
								<Grid item xs={12} sm={12} md={6}>
									<button
										className={styles.registrationButtoncadastrar}
										type="button"
										onClick={goTo}
									>
										Gerar etiqueta
									</button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Modal> */}
		</>
	);
}

export default TableModal;
