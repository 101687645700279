import { BusinessRepository } from "../repository/business_repository";

export class BusinessService {

	constructor() {
		this.businessRepository = new BusinessRepository();
	}

	async get(params) {
		const business = await this.businessRepository.get(params);
		return business;
	}

	async getById(businessId) {
		const business = await this.businessRepository.getById(businessId);
		return business;
	}

	async save(business) {
		return await this.businessRepository.save(business);
	}

	async update(businessId, business) {
		return await this.businessRepository.update(businessId, business);
	}

	async changeVisibility(id) {
		await this.businessRepository.changeVisibility(id);
	}

	async finalize(id) {
		await this.businessRepository.finalize(id);
	}

	async reactive(id) {
		await this.businessRepository.reactive(id);
	}

	async finalizeTest(id) {
		await this.businessRepository.finalizeTest(id);
	}

	async activeFinalizeTest(id) {
		await this.businessRepository.activeFinalizeTest(id);
	}

}