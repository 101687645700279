import React, { useEffect, useState } from "react";

import { BusinessService } from "../../../service/business_service";
import { getDateWithoutTimezone } from "../../../utils/utils";
//import data activities
import Table from "../../TableGenerator";
import Loading from "../../Loading";

//column

function TableTesting(props) {
	const [search, setSearch] = useState("");
	const [companies, setCompanies] = useState([]);
	const [totalBusinessData, setTotalBusinessData] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const columns = [
		{
			id:"razaoSocial",
			label: "Razão social",
		},
		{
			id: "nomeFantasia",
			label: "Nome fantasia",
		},
		{
			id: "licenca",
			label: "Período de teste",
		}
	];

	const businessService = new BusinessService();

	useEffect(() => setSearch(props.search), [props]);

	const updateData = async (page, rowsPerPage, orderBy, order) => {
		let filter = {
			licensa: {
				em_teste: true,
				contrato_encerrado: false,
				OR: [
					{
						teste_data_fim: {
							equals: null,
						},
					},
					{
						teste_data_fim: {
							gte: new Date()
						},
					}
				]
			}
		};

		let orderByValue =  JSON.stringify(
			{
				createdAt: "desc"
			}
		);

		if(orderBy === columns[0].id){
			orderByValue =  JSON.stringify(
				{
					nome: order
				}
			);
		} else if(orderBy === columns[1].id){
			orderByValue =  JSON.stringify(
				{
					nome_fantasia: order
				}
			);
		}else if(orderBy === columns[2].id){
			orderByValue =  JSON.stringify(	
				{
					licensa: {
						teste_data_inicio: order,
						teste_data_fim: order,
					}
				}
			);
		}

		const params = {
			page: page + 1,
			limit: rowsPerPage,
			filterValue: encodeURI(JSON.stringify(filter)),
			orderBy: orderByValue
		};

		let business = await businessService.get(params);
		setTotalBusinessData(business.totalItems);

		business = business.business.map((businessItem) => {
			return {
				id: businessItem.id,
				razaoSocial: businessItem.nome,
				nomeFantasia: businessItem.nome_fantasia ?? "-",
				licenca: businessItem.licensa?.teste_data_inicio && businessItem.licensa?.teste_data_fim ?
					`${getDateWithoutTimezone(businessItem.licensa?.teste_data_inicio).toLocaleDateString("pt-BR")} - ${getDateWithoutTimezone(businessItem.licensa?.teste_data_fim).toLocaleDateString("pt-BR")}`
					: "Sem licença",
			};
		});
		setCompanies(business);

		setIsLoading(false);
	};

	const dataFilter = companies.filter((item) =>
		JSON.stringify(item).toLowerCase().includes(search.toLowerCase())
	);

	const handleDelete = async (id) => {
		await businessService.changeVisibility(id);
	};

	const custonOp = [
		{
			item: "Usuários",
			func: function handleOpen(id) {
				window.location = `./empresas/${id}/usuarios`;
			},
		},
		{
			item: "Finalizar teste",
			func: function handleOpen(id) {
				finalize(id);
			},
		},
	];

	const finalize = async (id) => {
		await businessService.finalizeTest(id);
		setCompanies(companies.filter((item) => item.id !== id));
	};

	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) :(
			<>
				<Table
					listSize={totalBusinessData}
					updateData={updateData}
					data={dataFilter}
					columns={columns}
					editHref="/admin/empresas/cadastro?id="
					handleDelete={handleDelete}
					custonOpition={custonOp}
				/>
			</>
		);
}

export default TableTesting;
