import React, { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import InputMask from "../../../components/InputMask";
import RegisterArea from "../../../components/RegistrationArea";
import TopMenuAdmin from "../../../components/TopMenuAdmin";
import styles from "./businessCad.module.css";

//material ul imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

const stylesItem = {
	bgcolor: "rgba(0, 0, 0, 0)",
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "start",
	m: 0,
	p: 0,
};

import { Typography } from "@mui/material";
import Loading from "../../../components/Loading";
import InputData from "../../../components/InputData";
import { BusinessService } from "../../../service/business_service";
import { LicenseService } from "../../../service/license_service";

function BusinessCad() {
	const [business, setBusiness] = useState({endereco: {}, licensa: {teste: false}});
	const [searchParams] = useSearchParams();
	const [id, setId] = useState(null);
	const [message, setMessage] = useState("");
	const [typeMessage, setTypeMessage] = useState("info");
	const [errorMessageBairro, setErrorMessageBairro] = useState(false);
	const [errorMessageCidade, setErrorMessageCidade] = useState(false);
	const [errorMessageEndereco, setErrorMessageEndereco] = useState(false);
	const [errorMessageCep, setErrorMessageCep] = useState(false);
	const [errorMessageNome, setErrorMessageNome] = useState(false);
	const [errorMessageEstado, setErrorMessageEstado] = useState(false);
	const [errorMessageCpfCnpj, setErrorMessageCpfCnpj] = useState(false);
	const [errorMessageDataInicial, setErrorMessageDataInicial] = useState(false);
	const [errorMessageDataFinal, setErrorMessageDataFinal] = useState(false);
	const [errorMessageQuantidadeUsuarios, setErrorMessageQuantidadeUsuarios] = useState(false);
	const [errorMessageTamanhoArea, setErrorMessageTamanhoArea] = useState(false);
	const [errorMessageQuantidadePropriedades, setErrorMessageQuantidadePropriedades] = useState(false);
	const [errorMessageValorMensal, setErrorMessageValorMensal] = useState(false);
	const [errorMessageValorDesconto, setErrorMessageValorDesconto] = useState(false);
	const [errorMessageDiaPagamento, setErrorMessageDiaPagamento] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const nomeRef = useRef(null);
	const cpfRef = useRef(null);
	const cepRef = useRef(null);
	const estadoRef = useRef(null);
	const cidadeRef = useRef(null);
	const bairroRef = useRef(null);
	const enderecoRef = useRef(null);
	const quantidadeUsuariosRef = useRef(null);
	const quantidadePropriedadesRef = useRef(null);
	const tamanhoAreaRef = useRef(null);
	const valorMensalRef = useRef(null);
	const ValorDescontoRef = useRef(null);
	const diaPagamentoRef = useRef(null);
	
	const businessService = new BusinessService();
	const licenseService = new LicenseService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const businessId = searchParams.get("id");
		if (businessId) {
			setId(businessId);

			const businessById = await businessService.getById(businessId);
			
			businessById.telefone = businessById.telefone?.replace("+55", "");
			businessById.licensa.teste = businessById.licensa?.em_teste ?? false;
			businessById.licensa.data_inicio = businessById.licensa?.data_inicio ?? businessById.licensa?.teste_data_inicio;
			businessById.licensa.data_fim = businessById.licensa?.data_fim ?? businessById.licensa?.teste_data_fim;
			setBusiness(businessById);
		}
		setIsLoading(false);
	};

	const validationSave = async () => {
		if (
			business.endereco.bairro &&
			business.endereco.bairro !== "" &&
			business.endereco.cidade &&
			business.endereco.cidade !== "" &&
			business.endereco.cep &&
			business.endereco.cep !== "" &&
			enderecoRef.current.value &&
			enderecoRef.current.value !== "" &&
			business.nome &&
			business.nome !== "" &&
			business.endereco.uf &&
			business.endereco.uf !== "0" &&
			business.cpf_cnpj &&
			(business.cpf_cnpj.length === 11 || business.cpf_cnpj.length === 14) &&
			business.licensa?.data_inicio &&
			business.licensa?.data_fim &&
			business.licensa?.quantidade_usuarios &&
			business.licensa?.tamanho_area &&
			business.licensa?.quantidade_propriedades &&
			business.licensa?.valor_mensal &&
			business.licensa?.valor_desconto &&
			business.licensa?.dia_pagamento
		) {
			await handleSave();
			setTypeMessage("success");
			setMessage("cadastro salvo!!!");
			return true;
		} else {
			let message = "Um ou mais campos vazios. - (";
			if (!business.nome || business.nome === "") {
				message += "Nome/Razao, ";
				nomeRef.current.focus();
				setErrorMessageNome(true);
			} else {
				setErrorMessageNome(false);
			}
			if (!business.cpf_cnpj || business.cpf_cnpj.length !== 11 && business.cpf_cnpj.length !== 14) {
				message += "CPF/CNPJ, ";
				setErrorMessageCpfCnpj(true);
			} else {
				setErrorMessageCpfCnpj(false);
			}
			if (!business.endereco.cep || business.endereco.cep === "") {
				message += "CEP, ";
				cepRef.current.focus();
				setErrorMessageCep(true);
			} else {
				setErrorMessageCep(false);
			}
			if (!business.endereco.cidade || business.endereco.cidade === "") {
				message += "Cidade, ";
				cidadeRef.current.focus();
				setErrorMessageCidade(true);
			} else {
				setErrorMessageCidade(false);
			}
			if (!business.endereco.uf || business.endereco.uf === "0") {
				message += "Estado, ";
				estadoRef.current.focus();
				setErrorMessageEstado(true);
			} else {
				setErrorMessageEstado(false);
			}
			if (!business.endereco.bairro || business.endereco.bairro === "") {
				message += "Bairro, ";
				bairroRef.current.focus();
				setErrorMessageBairro(true);
			} else {
				setErrorMessageBairro(false);
			}
			if (!enderecoRef.current.value || enderecoRef.current.value === "") {
				message += "Endereço, ";
				enderecoRef.current.focus();
				setErrorMessageEndereco(true);
			} else {
				setErrorMessageEndereco(false);
			}
			if (!business.licensa?.data_inicio) {
				message += "Data de inicio da licença, ";
				setErrorMessageDataInicial(true);
			} else {
				setErrorMessageDataInicial(false);
			}
			if (!business.licensa?.data_fim) {
				message += "Data de encerramento da licença, ";
				setErrorMessageDataFinal(true);
			} else {
				setErrorMessageDataFinal(false);
			}
			if (business.licensa?.quantidade_usuarios) {
				message += "Quantidade de usuários, ";
				setErrorMessageQuantidadeUsuarios(true);
			} else {
				setErrorMessageQuantidadeUsuarios(false);
			}
			if (business.licensa?.quantidade_propriedades) {
				message += "Quantidade de propriedades, ";
				setErrorMessageQuantidadePropriedades(true);
			} else {
				setErrorMessageQuantidadePropriedades(false);
			}
			if (business.licensa?.tamanho_area) {
				message += "Tamanho da área, ";
				setErrorMessageTamanhoArea(true);
			} else {
				setErrorMessageTamanhoArea(false);
			}
			if (business.licensa?.valor_mensal) {
				message += "Valor mensal, ";
				setErrorMessageValorMensal(true);
			} else {
				setErrorMessageValorMensal(false);
			}
			if (business.licensa?.valor_desconto) {
				message += "Valor de desconto ";
				setErrorMessageValorDesconto(true);
			} else {
				setErrorMessageValorDesconto(false);
			}
			if (business.licensa?.dia_pagamento) {
				message += "Dia do pagamento, ";
				setErrorMessageDiaPagamento(true);
			} else {
				setErrorMessageDiaPagamento(false);
			}

			message += ")";
			setTypeMessage("error");
			setMessage(message);
			return false;
		}
	};

	const handleSave = async () => {

		let businessToSave = {
			name: business.nome,
			cpfCnpj: business.cpf_cnpj,
			alias: business.nome_fantasia,
			phone: business.telefone ? `+55${business.telefone}` : null,
			email: business.email,
			neighborhood: business.endereco.bairro,
			cep: business.endereco.cep,
			city: business.endereco.cidade,
			federativeUnit: business.endereco.uf,
			address: enderecoRef.current.value,
			observation: business.endereco.observacao,
		};

		let licenseToSave = {
			is_test: business.licensa?.teste ?? false,
			start_date: !business.licensa?.teste ? business.licensa?.data_inicio : null,
			final_date: !business.licensa?.teste ? business.licensa?.data_fim : null,
			test_start_date: business.licensa?.teste ? business.licensa?.data_inicio : null,
			test_final_date: business.licensa?.teste ? business.licensa?.data_fim : null,
			users_quantity: Number(business.licensa?.quantidade_usuarios),
			area_size: Number(business.licensa?.tamanho_area),
			properties_quantity: Number(business.licensa?.quantidade_propriedades),
			activities: true,
			manage_input: business.licensa?.gerencia_insumos ?? false,
			incidents: business.licensa?.ocorrencias ?? false,
			fiscal_integrations: business.licensa?.integracoes_fiscais ?? false,
			monthly_value: Number(business.licensa?.valor_mensal),
			discount_value: Number(business.licensa?.valor_desconto),
			payment_day: Number(business.licensa?.dia_pagamento),
			assigned_contract: false,
		};
		if (!id) {
			const savedBusiness = await businessService.save(businessToSave);
			licenseToSave.businessId = savedBusiness.id;
			await licenseService.save(licenseToSave);
			setTypeMessage("success");
			setMessage("cadastro salvo!!!");
		} else {
			businessToSave.id = business.id;
			await businessService.update(business.id, businessToSave);
			if(business.licensa?.id){
				licenseToSave.businessId = business.id;
				licenseToSave.id = business.licensa?.id;
				await licenseService.update(business.licensa?.id, licenseToSave);
			} else {
				licenseToSave.businessId = business.id;
				await licenseService.save(licenseToSave);
			}
		}
		setTypeMessage("success");
		setMessage("cadastro atualizado!!!");
	};

	function handleChangeCEP(event) {
		let cepText = event.target.value;
		if (cepText.length > 7) {
			fetch(`https://viacep.com.br/ws/${cepText}/json/`, {
				method: "GET",
			})
				.then((response) => response.json())
				.then((data) => {
					if (!("erro" in data)) {
						setBusiness({...business, endereco: {
							...business.endereco, 
							uf: data.uf,
							bairro: data.bairro,
							cidade: data.localidade
						}});
					}
				})
				.catch(() => {
					setBusiness({...business, endereco: {
						...business.endereco, 
						uf: "0",
					}});
				});
		}
	}

	return  isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<div>
				<TopMenuAdmin
					open={["admin", "business"]}
				/>
				<Box component="main" sx={{ p: 0, mt: 12 }}>
					<Box sx={{ width: "100%", px: 3 }}>
						<Grid
							container
							rowSpacing={3}
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						>
							<Grid item xs={12} sm={12} md={12}>
								<Item>
									<RegisterArea
										title={
											id
												? "Editar empresa"
												: "Cadastro de empresa"
										}
										href="/admin/empresas"
										typeMessage={typeMessage}
										message={message}
										salveFunc={validationSave}
									>
										{/* inputs da pagina de resgitro */}
										<Grid
											container
											rowSpacing={3}
											columnSpacing={{ xs: 1, sm: 2, md: 3 }}
										>
											<Grid item xs={12} sm={12} md={6}>
												<Item sx={stylesItem}>
													<div
														className={
															errorMessageNome
																? styles.errControl
																: styles.noErrControl
														}
													>	<Box	Box sx={{
															border: "1px solid rgba(0,0,0,0.3)",
															borderRadius: "8px",
															width: "100%",
															display: "flex",
															justifyContent: "flex-start",
															flexDirection: "column",
															alignItems: "flex-start",
															px: 1,
															pr:2,
															position: "relative",
															m:0
														}}>
															<Typography 
																variant="caption" 
																display="block" 
																gutterBottom 
																sx={{
																	top:-11,
																	position:"absolute",
																	px:1,
																	backgroundColor:"white",
																	visibility:(business.nome && business.nome !== "" ? "visible" : "hidden"),
																	transition:"all 0.5s ease"
																}} 
															>
																Nome/Razao *
															</Typography>
															<p
																className={
																	errorMessageNome
																		? styles.errText
																		: styles.noErrText
																}
															>
															*
															</p>
															<input
																ref={nomeRef}
																type="text"
																placeholder="Nome/Razao *"
																id="nome"
																className={styles.testInput}
																value={business.nome}
																onChange={(e) => setBusiness({...business, nome: e.target.value})}
															/>
														</Box>
													</div>
												</Item>
											</Grid>
											<Grid item xs={12} sm={12} md={6}>
												<Item sx={stylesItem}>
													<div
														className={
															errorMessageCpfCnpj
																? styles.errControl
																: styles.noErrControl
														}
													>
														<Box	Box sx={{
															border: "1px solid rgba(0,0,0,0.3)",
															borderRadius: "8px",
															width: "100%",
															display: "flex",
															justifyContent: "flex-start",
															flexDirection: "column",
															alignItems: "flex-start",
															px: 1,
															pr:2,
															position: "relative",
															m:0
														}}>
															<Typography 
																variant="caption" 
																display="block" 
																gutterBottom 
																sx={{
																	top:-11,
																	position:"absolute",
																	px:1,
																	backgroundColor:"white",
																	visibility:(business.cpf_cnpj && business.cpf_cnpj !== "" ? "visible" : "hidden"),
																	transition:"all 0.5s ease"
																}} 
															>
																CPF/CNPJ *
															</Typography>
															<p
																className={
																	errorMessageCpfCnpj
																		? styles.errText
																		: styles.noErrText
																}
															>
															*
															</p>
															<InputMask
																ref={cpfRef}
																placeholder="CPF/CNPJ *"
																name="CPF/CNPJ"
																class={styles.testInput}
																mask={
																	business.cpf_cnpj && business.cpf_cnpj.length > 11
																		? "99.999.999/9999-99"
																		: "999.999.999-999"
																}
																value={business.cpf_cnpj}
																onChange={(e) => setBusiness({...business, cpf_cnpj: e.target.value})}
																onPaste={(e) => { 
																	const pastedValue = e.clipboardData.getData("Text");
																	const cleanedValue = pastedValue.replace(/\D/g, ""); 
																	setBusiness({...business, cpf_cnpj: cleanedValue});
																	e.preventDefault();
																}}
															/>
														</Box>
													</div>
													<Box	Box sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
														<Typography 
															variant="caption"
															display="block" 
															gutterBottom 
															sx={{
																top:-11,
																position:"absolute",
																px:1,
																backgroundColor:"white",
																visibility:(business.nome_fantasia && business.nome_fantasia !== "" ? "visible" : "hidden"),
																transition:"all 0.5s ease"
															}} 
														>
															Nome Fantasia/Apelido *
														</Typography>
														<input
															type="text"
															placeholder="Nome Fantasia/Apelido *"
															id="nomeFantasia"
															className={styles.testInput}
															value={business.nome_fantasia}
															onChange={(e) => setBusiness({...business, nome_fantasia: e.target.value})}
														/>
													</Box>
												</Item>
											</Grid>
											<Grid item xs={12} sm={12} md={6}>
												<Item sx={stylesItem}>
													<Box	Box sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
														<Typography 
															variant="caption" 
															display="block" 
															gutterBottom 
															sx={{
																top:-11,
																position:"absolute",
																px:1,
																backgroundColor:"white",
																visibility:(business.email && business.email !== "" ? "visible" : "hidden"),
																transition:"all 0.5s ease"
															}} 
														>
															E-mail *
														</Typography>
														<input 
															type="email" 
															placeholder="E-mail *" 
															id="email" 
															className={styles.testInput} 
															value={business.email} 
															onChange={(e) => setBusiness({...business, email: e.target.value})}
														/>
													</Box>
												</Item>
											</Grid>
											<Grid item xs={12} sm={12} md={6}>
												<Item sx={stylesItem}>
													<Box	Box sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
														<Typography 
															variant="caption" 
															display="block" 
															gutterBottom 
															sx={{
																top:-11,
																position:"absolute",
																px:1,
																backgroundColor:"white",
																visibility:(business.telefone && business.telefone !== "" ? "visible" : "hidden"),
																transition:"all 0.5s ease"
															}}
														>
															Telefone
														</Typography>
														<InputMask
															placeholder="Telefone"
															class={styles.testInput}
															mask="(99)99999-9999"
															value={business.telefone}
															onChange={(e) => setBusiness({...business, telefone: e.target.value})}
														/>
													</Box>
												</Item>
											</Grid>
											<Grid item xs={12} sm={12} md={12}>
												<Item sx={stylesItem}>
													<div className={styles.registrationTitle}>
														<p>Preencha os dados de localização</p>
													</div>
												</Item>
											</Grid>
											<Grid item xs={12} sm={6} md={3}>
												<Item sx={stylesItem}>
													<div
														className={
															errorMessageCep
																? styles.errControl
																: styles.noErrControl
														}
													>
														<Box	Box sx={{
															border: "1px solid rgba(0,0,0,0.3)",
															borderRadius: "8px",
															width: "100%",
															display: "flex",
															justifyContent: "flex-start",
															flexDirection: "column",
															alignItems: "flex-start",
															px: 1,
															pr:2,
															position: "relative",
															m:0
														}}>
															<Typography 
																variant="caption" 
																display="block" 
																gutterBottom 
																sx={{
																	top:-11,
																	position:"absolute",
																	px:1,
																	backgroundColor:"white",
																	visibility:(business.endereco.cep && business.endereco.cep !== "" ? "visible" : "hidden"),
																	transition:"all 0.5s ease"
																}} 
															>
																CEP *
															</Typography>
															<p
																className={
																	errorMessageCep
																		? styles.errText
																		: styles.noErrText
																}
															>
															*
															</p>
															<input
																ref={cepRef}
																type="text"
																id="cep"
																placeholder="CEP *"
																className={styles.testInput}
																onKeyUp={(e) => handleChangeCEP(e)}
																value={business.endereco.cep}
																onChange={(e) => setBusiness({...business, endereco: {...business.endereco, cep: e.target.value}})}
															/>
														</Box>
													</div>
												</Item>
											</Grid>
											<Grid item xs={12} sm={6} md={3}>
												<Item sx={stylesItem}>
													<div
														className={
															errorMessageEstado
																? styles.errControl
																: styles.noErrControl
														}
													>
														<Box	Box sx={{
															border: "1px solid rgba(0,0,0,0.3)",
															borderRadius: "8px",
															width: "100%",
															display: "flex",
															justifyContent: "flex-start",
															flexDirection: "column",
															alignItems: "flex-start",
															px: 1,
															pr:2,
															position: "relative",
															m:0
														}}>
															<Typography 
																variant="caption" 
																display="block" 
																gutterBottom 
																sx={{
																	top:-11,
																	position:"absolute",
																	px:1,
																	backgroundColor:"white",
																	visibility:(business.endereco.uf && business.endereco.uf !== "0" ? "visible" : "hidden"),
																	transition:"all 0.5s ease"
																}} 
															>
																Estado *
															</Typography>
															<p
																className={
																	errorMessageEstado
																		? styles.errText
																		: styles.noErrText
																}
															>
															*
															</p>
															<select
																ref={estadoRef}
																id="estado"
																onChange={(e) => setBusiness({...business, endereco: {...business.endereco, uf: e.target.value}})}
																value={business.endereco.uf}
																className={styles.testInput}
															>
																<option value={"0"}>
																	{business.endereco.uf && business.endereco.uf !== "0"
																		? "--------------- *"
																		: "Estado *"}
																</option>
																<option value="AC">Acre</option>
																<option value="AL">Alagoas</option>
																<option value="AP">Amapá</option>
																<option value="AM">Amazonas</option>
																<option value="BA">Bahia</option>
																<option value="CE">Ceará</option>
																<option value="DF">Distrito Federal</option>
																<option value="ES">Espirito Santo</option>
																<option value="GO">Goiás</option>
																<option value="MA">Maranhão</option>
																<option value="MS">Mato Grosso do Sul</option>
																<option value="MT">Mato Grosso</option>
																<option value="MG">Minas Gerais</option>
																<option value="PA">Pará</option>
																<option value="PB">Paraíba</option>
																<option value="PR">Paraná</option>
																<option value="PE">Pernambuco</option>
																<option value="PI">Piauí</option>
																<option value="RJ">Rio de Janeiro</option>
																<option value="RN">Rio Grande do Norte</option>
																<option value="RS">Rio Grande do Sul</option>
																<option value="RO">Rondônia</option>
																<option value="RR">Roraima</option>
																<option value="SC">Santa Catarina</option>
																<option value="SP">São Paulo</option>
																<option value="SE">Sergipe</option>
																<option value="TO">Tocantins</option>
															</select>
														</Box>
													</div>
												</Item>
											</Grid>
											<Grid item xs={12} sm={6} md={3}>
												<Item sx={stylesItem}>
													<div
														className={
															errorMessageCidade
																? styles.errControl
																: styles.noErrControl
														}
													>
														<Box	Box sx={{
															border: "1px solid rgba(0,0,0,0.3)",
															borderRadius: "8px",
															width: "100%",
															display: "flex",
															justifyContent: "flex-start",
															flexDirection: "column",
															alignItems: "flex-start",
															px: 1,
															pr:2,
															position: "relative",
															m:0
														}}>
															<Typography 
																variant="caption" 
																display="block" 
																gutterBottom 
																sx={{
																	top:-11,
																	position:"absolute",
																	px:1,
																	backgroundColor:"white",
																	visibility:(business.endereco.cidade && business.endereco.cidade !== "" ? "visible" : "hidden"),
																	transition:"all 0.5s ease"
																}}
															>
																Cidade *
															</Typography>
															<p
																className={
																	errorMessageCidade
																		? styles.errText
																		: styles.noErrText
																}
															>
															*
															</p>
															<input 
																ref={cidadeRef}
																type="text" 
																placeholder="Cidade *" 
																id="cidade" 
																className={styles.testInput} 
																value={business.endereco.cidade} 
																onChange={e=> setBusiness({...business, endereco: {...business.endereco, cidade: e.target.value}})}
															/>
														</Box>
													</div>
												</Item>
											</Grid>
											<Grid item xs={12} sm={6} md={3}>
												<Item sx={stylesItem}>
													<div
														className={
															errorMessageBairro
																? styles.errControl
																: styles.noErrControl
														}
													><Box	Box sx={{
															border: "1px solid rgba(0,0,0,0.3)",
															borderRadius: "8px",
															width: "100%",
															display: "flex",
															justifyContent: "flex-start",
															flexDirection: "column",
															alignItems: "flex-start",
															px: 1,
															pr:2,
															position: "relative",
															m:0
														}}>
															<Typography 
																variant="caption" 
																display="block" 
																gutterBottom 
																sx={{
																	top:-11,
																	position:"absolute",
																	px:1,
																	backgroundColor:"white",
																	visibility:(business.endereco.bairro && business.endereco.bairro !== "" ? "visible" : "hidden"),
																	transition:"all 0.5s ease"
																}}
															>
																Bairro *
															</Typography>
															<p
																className={
																	errorMessageBairro
																		? styles.errText
																		: styles.noErrText
																}
															>
															*
															</p>
															<input 
																ref={bairroRef}
																type="text" 
																placeholder="Bairro *" 
																id="bairro" 
																className={styles.testInput} 
																value={business.endereco.bairro} 
																onChange={e => setBusiness({...business, endereco: {...business.endereco, bairro: e.target.value}})}
															/>
														</Box>
													</div>
												</Item>
											</Grid>
											<Grid item xs={12} sm={12} md={12}>
												<Item sx={stylesItem}>
													<div
														className={
															errorMessageEndereco
																? styles.errControl
																: styles.noErrControl
														}
													><Box	Box sx={{
															border: "1px solid rgba(0,0,0,0.3)",
															borderRadius: "8px",
															width: "100%",
															display: "flex",
															justifyContent: "flex-start",
															flexDirection: "column",
															alignItems: "flex-start",
															px: 1,
															pr:2,
															position: "relative",
															m:0
														}}>
															<Typography 
																variant="caption" 
																display="block" 
																gutterBottom 
																sx={{
																	top:-11,
																	position:"absolute",
																	px:1,
																	backgroundColor:"white",
																	visibility:(business.endereco.endereco && business.endereco.endereco !== "" ? "visible" : "hidden"),
																	transition:"all 0.5s ease"
																}}
															>
																Endereço *
															</Typography>
															<p
																className={
																	errorMessageEndereco
																		? styles.errText
																		: styles.noErrText
																}
															>
															*
															</p>
															<input
																type="text"
																placeholder="Endereço *"
																id="endereco"
																className={styles.testInput}
																value={business.endereco.endereco}
																ref={enderecoRef}
																onChange={e => setBusiness({...business, endereco: {...business.endereco, endereco: e.target.value}})}
															/>
														</Box>
													</div>
												</Item>
											</Grid>
											<Grid item xs={12} sm={12} md={12}>
												<Item sx={stylesItem}>
													<Box	Box sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
														<Typography 
															variant="caption" 
															display="block" 
															gutterBottom 
															sx={{
																top:-11,
																position:"absolute",
																px:1,
																backgroundColor:"white",
																visibility:(business.endereco.observacao && business.endereco.observacao !== ""? "visible" : "hidden"),
																transition:"all 0.5s ease"
															}} 
														>
															Observações
														</Typography>
														<input
															type="text"
															id="observacao"
															placeholder="Observações"
															className={styles.testInput}
															value={business.endereco.observacao}
															onChange={e => setBusiness({...business, endereco: {...business.endereco, observacao: e.target.value}})}
														/>
													</Box>
												</Item>
											</Grid>
											<Grid item xs={12} sm={12} md={12}>
												<Item sx={stylesItem}>
													<div className={styles.registrationTitle}>
														<p>Preencha os dados de licença</p>
													</div>
												</Item>
											</Grid>
											<Grid item xs={6} sm={3} md={2}>
												<Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography 
														variant="caption" 
														display="block" 
														gutterBottom 
														sx={{
															top:-11,
															position:"absolute",
															px:1,
															backgroundColor:"white",
															visibility:(business.licensa?.data_inicio ? "visible" : "hidden"),
															transition:"all 0.5s ease"
														}} 
													>
														Data inicial*
													</Typography>
													<div
														className={
															errorMessageDataInicial ? styles.errControl : styles.noErrControl
														}
													>
														<p
															className={
																errorMessageDataInicial ? styles.errText : styles.noErrText
															}
														>
															*
														</p>
														<InputData 
															value={business.licensa?.data_inicio ? new Date(business.licensa?.data_inicio).toLocaleDateString("pt-BR") : null}
															placeholder="Data inicial *" 
															onDateChange={(date) => setBusiness({...business, licensa: {...business.licensa, data_inicio: date}})} 
															classN={styles.testInput}
														/>
													</div>
												</Box>
											</Grid>
											<Grid item xs={6} sm={3} md={2}>
												<Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography 
														variant="caption" 
														display="block" 
														gutterBottom 
														sx={{
															top:-11,
															position:"absolute",
															px:1,
															backgroundColor:"white",
															visibility:(business.licensa?.data_fim ? "visible" : "hidden"),
															transition:"all 0.5s ease"
														}} 
													>
														Data final*
													</Typography>
													<div
														className={
															errorMessageDataFinal ? styles.errControl : styles.noErrControl
														}
													>
														<p
															className={
																errorMessageDataFinal ? styles.errText : styles.noErrText
															}
														>
															*
														</p>
														<InputData 
															value={business.licensa?.data_fim ? new Date(business.licensa?.data_fim).toLocaleDateString("pt-BR") : null}
															placeholder="Data final *" 
															onDateChange={(date) => setBusiness({...business, licensa: {...business.licensa, data_fim: date}})} 
															classN={styles.testInput}
														/>
													</div>
												</Box>
											</Grid>
											<Grid item xs={6} sm={3} md={2}>
												<Item sx={stylesItem}>
													<div
														className={
															errorMessageQuantidadeUsuarios
																? styles.errControl
																: styles.noErrControl
														}
													>
														<Box Box sx={{
															border: "1px solid rgba(0,0,0,0.3)",
															borderRadius: "8px",
															width: "100%",
															display: "flex",
															justifyContent: "flex-start",
															flexDirection: "column",
															alignItems: "flex-start",
															px: 1,
															pr:2,
															position: "relative",
															m:0
														}}>
															<Typography 
																variant="caption" 
																display="block" 
																gutterBottom 
																sx={{
																	top:-11,
																	position:"absolute",
																	px:1,
																	backgroundColor:"white",
																	visibility:(business.licensa?.quantidade_usuarios ? "visible" : "hidden"),
																	transition:"all 0.5s ease"
																}}
															>
																Usuários *
															</Typography>
															<p
																className={
																	errorMessageQuantidadeUsuarios
																		? styles.errText
																		: styles.noErrText
																}
															>
															*
															</p>
															<input 
																ref={quantidadeUsuariosRef}
																type="number" 
																placeholder="Quantidade de usuários *" 
																id="usuario" 
																className={styles.testInput} 
																value={business.licensa?.quantidade_usuarios} 
																onChange={e=> setBusiness({...business, licensa: {...business.licensa, quantidade_usuarios: e.target.value}})}
															/>
														</Box>
													</div>
												</Item>
											</Grid>
											<Grid item xs={6} sm={3} md={2}>
												<Item sx={stylesItem}>
													<div
														className={
															errorMessageQuantidadePropriedades
																? styles.errControl
																: styles.noErrControl
														}
													>
														<Box Box sx={{
															border: "1px solid rgba(0,0,0,0.3)",
															borderRadius: "8px",
															width: "100%",
															display: "flex",
															justifyContent: "flex-start",
															flexDirection: "column",
															alignItems: "flex-start",
															px: 1,
															pr:2,
															position: "relative",
															m:0
														}}>
															<Typography 
																variant="caption" 
																display="block" 
																gutterBottom 
																sx={{
																	top:-11,
																	position:"absolute",
																	px:1,
																	backgroundColor:"white",
																	visibility:(business.licensa?.quantidade_propriedades ? "visible" : "hidden"),
																	transition:"all 0.5s ease"
																}}
															>
																Propriedades *
															</Typography>
															<p
																className={
																	errorMessageQuantidadePropriedades
																		? styles.errText
																		: styles.noErrText
																}
															>
															*
															</p>
															<input 
																ref={quantidadePropriedadesRef}
																type="number" 
																placeholder="Quantidade de propriedades *" 
																id="propriedade" 
																className={styles.testInput} 
																value={business.licensa?.quantidade_propriedades} 
																onChange={e=> setBusiness({...business, licensa: {...business.licensa, quantidade_propriedades: e.target.value}})}
															/>
														</Box>
													</div>
												</Item>
											</Grid>
											<Grid item xs={6} sm={3} md={2}>
												<Item sx={stylesItem}>
													<div
														className={
															errorMessageTamanhoArea
																? styles.errControl
																: styles.noErrControl
														}
													>
														<Box Box sx={{
															border: "1px solid rgba(0,0,0,0.3)",
															borderRadius: "8px",
															width: "100%",
															display: "flex",
															justifyContent: "flex-start",
															flexDirection: "column",
															alignItems: "flex-start",
															px: 1,
															pr:2,
															position: "relative",
															m:0
														}}>
															<Typography 
																variant="caption" 
																display="block" 
																gutterBottom 
																sx={{
																	top:-11,
																	position:"absolute",
																	px:1,
																	backgroundColor:"white",
																	visibility:(business.licensa?.tamanho_area ? "visible" : "hidden"),
																	transition:"all 0.5s ease"
																}}
															>
																Área *
															</Typography>
															<p
																className={
																	errorMessageTamanhoArea
																		? styles.errText
																		: styles.noErrText
																}
															>
															*
															</p>
															<input 
																ref={tamanhoAreaRef}
																type="number" 
																placeholder="Tamanho da área *" 
																id="tamanhoArea" 
																className={styles.testInput} 
																value={business.licensa?.tamanho_area} 
																onChange={e=> setBusiness({...business, licensa: {...business.licensa, tamanho_area: e.target.value}})}
															/>
														</Box>
													</div>
												</Item>
											</Grid>
											<Grid item xs={6} sm={3} md={2}>
												<Item sx={stylesItem}>
													<div
														className={
															errorMessageValorMensal
																? styles.errControl
																: styles.noErrControl
														}
													>
														<Box Box sx={{
															border: "1px solid rgba(0,0,0,0.3)",
															borderRadius: "8px",
															width: "100%",
															display: "flex",
															justifyContent: "flex-start",
															flexDirection: "column",
															alignItems: "flex-start",
															px: 1,
															pr:2,
															position: "relative",
															m:0
														}}>
															<Typography 
																variant="caption" 
																display="block" 
																gutterBottom 
																sx={{
																	top:-11,
																	position:"absolute",
																	px:1,
																	backgroundColor:"white",
																	visibility:(business.licensa?.valor_mensal ? "visible" : "hidden"),
																	transition:"all 0.5s ease"
																}}
															>
																Valor mensal *
															</Typography>
															<p
																className={
																	errorMessageValorMensal
																		? styles.errText
																		: styles.noErrText
																}
															>
															*
															</p>
															<input 
																ref={valorMensalRef}
																type="number" 
																placeholder="Valor mensal *" 
																id="valorMensal" 
																className={styles.testInput} 
																value={business.licensa?.valor_mensal} 
																onChange={e=> setBusiness({...business, licensa: {...business.licensa, valor_mensal: e.target.value}})}
															/>
														</Box>
													</div>
												</Item>
											</Grid>
											<Grid item xs={6} sm={3} md={2}>
												<Item sx={stylesItem}>
													<div
														className={
															errorMessageValorDesconto
																? styles.errControl
																: styles.noErrControl
														}
													>
														<Box Box sx={{
															border: "1px solid rgba(0,0,0,0.3)",
															borderRadius: "8px",
															width: "100%",
															display: "flex",
															justifyContent: "flex-start",
															flexDirection: "column",
															alignItems: "flex-start",
															px: 1,
															pr:2,
															position: "relative",
															m:0
														}}>
															<Typography 
																variant="caption" 
																display="block" 
																gutterBottom 
																sx={{
																	top:-11,
																	position:"absolute",
																	px:1,
																	backgroundColor:"white",
																	visibility:(business.licensa?.valor_desconto ? "visible" : "hidden"),
																	transition:"all 0.5s ease"
																}}
															>
																Desconto *
															</Typography>
															<p
																className={
																	errorMessageValorDesconto
																		? styles.errText
																		: styles.noErrText
																}
															>
															*
															</p>
															<input 
																ref={ValorDescontoRef}
																type="number" 
																placeholder="Valor de desconto *" 
																id="valorDesconto" 
																className={styles.testInput} 
																value={business.licensa?.valor_desconto} 
																onChange={e=> setBusiness({...business, licensa: {...business.licensa, valor_desconto: e.target.value}})}
															/>
														</Box>
													</div>
												</Item>
											</Grid>
											<Grid item xs={6} sm={3} md={2}>
												<Item sx={stylesItem}>
													<div
														className={
															errorMessageDiaPagamento
																? styles.errControl
																: styles.noErrControl
														}
													>
														<Box Box sx={{
															border: "1px solid rgba(0,0,0,0.3)",
															borderRadius: "8px",
															width: "100%",
															display: "flex",
															justifyContent: "flex-start",
															flexDirection: "column",
															alignItems: "flex-start",
															px: 1,
															pr:2,
															position: "relative",
															m:0
														}}>
															<Typography 
																variant="caption" 
																display="block" 
																gutterBottom 
																sx={{
																	top:-11,
																	position:"absolute",
																	px:1,
																	backgroundColor:"white",
																	visibility:(business.licensa?.dia_pagamento ? "visible" : "hidden"),
																	transition:"all 0.5s ease"
																}}
															>
																Pagamento *
															</Typography>
															<p
																className={
																	errorMessageDiaPagamento
																		? styles.errText
																		: styles.noErrText
																}
															>
															*
															</p>
															<input 
																ref={diaPagamentoRef}
																type="number" 
																placeholder="Dia do pagamento *" 
																id="diaPagamento" 
																className={styles.testInput} 
																value={business.licensa?.dia_pagamento} 
																onChange={e=> setBusiness({...business, licensa: {...business.licensa, dia_pagamento: e.target.value}})}
															/>
														</Box>
													</div>
												</Item>
											</Grid>
											<Grid item xs={6} sm={3} md={2}>
												<Item sx={stylesItem}>
													<div className={styles.checkboxContainer}>
														<input
															type="checkbox"
															className={styles.inputCheckbox}
															name="gerenciaInsumos"
															id="gerenciaInsumos"
															checked={
																business.licensa?.gerencia_insumos
															}
															onChange={() => setBusiness({...business, licensa: {...business.licensa, gerencia_insumos: !(business.licensa?.gerencia_insumos ?? false)}})}
														/>
														<label htmlFor="gerenciaInsumos">Estoque de insumos</label>
													</div>
												</Item>
											</Grid>
											<Grid item xs={6} sm={3} md={2}>
												<Item sx={stylesItem}>
													<div className={styles.checkboxContainer}>
														<input
															type="checkbox"
															className={styles.inputCheckbox}
															name="ocorrencias"
															id="ocorrencias"
															checked={
																business.licensa?.ocorrencias
															}
															onChange={() => setBusiness({...business, licensa: {...business.licensa, ocorrencias: !(business.licensa?.ocorrencias ?? false)}})}
														/>
														<label htmlFor="ocorrencias">Acesso a ocorrências</label>
													</div>
												</Item>
											</Grid>
											<Grid item xs={6} sm={3} md={2}>
												<Item sx={stylesItem}>
													<div className={styles.checkboxContainer}>
														<input
															type="checkbox"
															className={styles.inputCheckbox}
															name="integracaofiscal"
															id="integracaofiscal"
															checked={
																business.licensa?.integracoes_fiscais
															}
															onChange={() => setBusiness({...business, licensa: {...business.licensa, integracoes_fiscais: !(business.licensa?.integracoes_fiscais ?? false)}})}
														/>
														<label htmlFor="ocorrencias">Integração fiscal</label>
													</div>
												</Item>
											</Grid>
											<Grid item xs={6} sm={3} md={2}>
												<Item sx={stylesItem}>
													<div className={styles.checkboxContainer}>
														<input
															type="checkbox"
															className={styles.inputCheckbox}
															name="teste"
															id="teste"
															checked={
																business.licensa?.teste
															}
															onChange={() => setBusiness({...business, licensa: {...business.licensa, teste: !(business.licensa?.teste ?? false)}})}
														/>
														<label htmlFor="teste">Conta de teste</label>
													</div>
												</Item>
											</Grid>
										</Grid>
									</RegisterArea>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</div>
		);
}
export default BusinessCad;
