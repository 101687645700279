/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import LogoHorizontal from "../../../../Assets/images/logoHorizontal.png";

import {
	Document,
	Font,
	Image,
	Page,
	StyleSheet,
	Text,
	View,
} from "@react-pdf/renderer";
import Roboto from "../../../../Assets/fonts/Roboto-Bold.ttf";
import { captalizeFirstLetterEacheWord } from "../../../../utils/utils";
import { BusinessService } from "../../../../service/business_service";
import { PropertyServiceService } from "../../../../service/property_service_service";
import { PropertyService } from "../../../../service/property_service";
import { ReportService } from "../../../../service/report_service";
import { UserService } from "../../../../service/user_service";

Font.register({
	family: "Roboto bold",
	format: "truetype",
	src: Roboto,
});

const styles = StyleSheet.create({
	page: {
		paddingBottom: "75px",
		paddingTop: "50px",
		paddingLeft: "50px",
		paddingRight: "50px",
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	logo: {
		width: "100px",
		height: "100px",
		marginRight: "-20px",
	},
	textTitleDocument: {
		color: "#03989E",
		textTransform: "uppercase",
		fontSize: "24px",
		fontFamily: "Roboto bold",
	},
	line: {
		height: "3px",
		backgroundColor: "#E0DFDF",
	},
	containerSubHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginVertical: "10px",
	},
	containerTotal: {
		alignItems: "flex-end",
	},
	textBold: {
		fontSize: "12px",
		fontFamily: "Roboto bold",
	},
	textBoldBig: {
		fontSize: "16px",
		marginBottom: "3px",
	},
	textNormal: {
		fontSize: "12px",
	},
	textBoldFazenda: {
		fontSize: "18px",
		fontFamily: "Roboto bold",
	},
	textBoldPropriedade: {
		fontSize: "12px",
		fontFamily: "Roboto bold",
	},
	body: {
		marginVertical: "15px",
	},
	mainTitle: {
		backgroundColor: "#03989E",
		padding: "3px 8px",
		textTransform: "uppercase",
		fontFamily: "Roboto bold",
		color: "white",
		borderRadius: "8px",
		letterSpacing: "1px",
		fontSize: "13px",
		marginVertical: "15px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
	},
	content: {
		fontSize: "9px",
	},
	tableTitle: {
		flexDirection: "row",
		justifyContent: "space-between",
		textTransform: "uppercase",
		fontFamily: "Roboto bold",
		marginBottom: "5px",
	},
	tableContent: {
		flexDirection: "row",
		justifyContent: "space-between",
		textTransform: "uppercase",
	},
	larguraTexto: {
		width: "16.60%",
		paddingHorizontal: "3px",
		marginBottom: "4px",
	},
	containerTotalTalhao: {
		fontSize: "11px",
		paddingTop: "30px",
		alignItems: "flex-end",
		textTransform: "uppercase",
	},
	containerTotalTalhaoGrey: {
		width: "70%",
		flexDirection: "row",
		backgroundColor: "#D9D9D9",
		paddingHorizontal: "15px",
		justifyContent: "space-between",
		padding: "7px",
		borderRadius: "8px",
	},
	containerNumberTotal: {
		width: "80%",
		flexDirection: "row",
		justifyContent: "space-between",
		paddingHorizontal: "30px",
	},
	footer: {
		position: "absolute",
		width: "100vw",
		height: "70px",
		bottom: 0,
		paddingHorizontal: "50px",
	},
	dividerFooter: {
		border: "1px solid #03989E",
	},
	logoFooter: {
		width: "60px",
		height: "60px",
	},
	containerFooter: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	paggingContainer: {
		display: "flex",
		flexDirection: "row",
	},
	paggingText: {
		alignItems: "flex-end",
		fontSize: "10px",
		borderRight: "1px solid #03989E",
		paddingHorizontal: "5px",
		color: "#03989E",
	},
	pagnumber: { color: "#03989E", paddingHorizontal: "5px" },
});

const PdfRelatorioAtividade = (props) => {
	const date = new Date().toLocaleDateString("pt-BR");
	const hora = new Date().toLocaleTimeString("pt-br", "h:mm A");
	const [user, setUser] = useState(null);
	const [data, setData] = useState([]);
	const atividadeID = props.atividadeId ? props.atividadeId : "-1";
	const talhaoID = props.talhaoId ? props.talhaoId : "-1";
	const parcelaID = props.parcelaId ? props.parcelaId : "-1";
	const responsaveisID = props.responsaveisId ? props.responsaveisId : "-1";
	const [loading, setLoading] = useState(true);
	const logo = props.logo;

	const reportService = new ReportService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const user = JSON.parse(localStorage.getItem("user"));
		setUser(user);

		const reportProps = {
			plot: String(talhaoID) !== "-1" ? talhaoID : null,
			subdivision: String(parcelaID) !== "-1" ? parcelaID : null,
			propertyService: String(atividadeID) !== "-1" ? atividadeID : null,
			property: propertyId,
			operator: String(responsaveisID) !== "-1" && String(responsaveisID) !== "0" ? responsaveisID : null,
			startDate: new Date(props.startDate),
			finalDate: new Date(props.finalDate)
		};
		const activityData = await reportService.getActivityData(reportProps);

		setData(activityData);
		setLoading(false);
	};

	const formatDate = (dateToFormat) => {
		const date = new Date(dateToFormat);
		return (
			("0" + date.getUTCDate()).slice(-2) +
			"/" +
			("0" + (date.getUTCMonth() + 1)).slice(-2) +
			"/" +
			date.getUTCFullYear()
		);
	};

	return (
		!loading ?
			<Document>
				<Page size="A4" style={styles.page}>
					<View style={styles.header} fixed>
						<View>
							<Text>Relatório de</Text>
							<Text style={styles.textTitleDocument}>Atividades</Text>
							{/* <Text style={{ textTransform: "uppercase", fontSize: "12px" }}>
								por {"variavel"}
							</Text> */}
							<Text style={styles.textBoldFazenda}>
								{data.empresa?.nome ?? "Não informado"}
							</Text>
							<Text style={styles.textBoldPropriedade}>
								{data.propriedade?.nome ?? "Não informado"}
							</Text>
						</View>
						{logo !== null &&
							<Image style={styles.logo} src={logo} />}
					</View>

					<View style={styles.line} fixed></View>

					<View style={styles.containerSubHeader}>
						<View>
							<Text style={styles.textBold}>Operador:</Text>
							<Text style={styles.textNormal}>{data.operador?.nome ?? "Todos"}</Text>
							<Text style={styles.textBold}>Área:</Text>
							<Text style={styles.textNormal}>{data.talhao?.nome ?? "Todas"}</Text>
							<Text style={styles.textBold}>Parcela:</Text>
							<Text style={styles.textNormal}>{data.subdivisao?.nome ?? "Todas"}</Text>
							<Text style={styles.textBold}>Atividade:</Text>
							<Text style={styles.textNormal}>{data.atividade?.nome ?? "Todas"}</Text>
							<Text style={styles.textBold}>Período: </Text>
							<Text style={styles.textNormal}>
								{formatDate(new Date(props.startDate))} a{" "}
								{formatDate(new Date(props.finalDate))}
							</Text>
						</View>
					</View>
					{data.dados.map((item, index) => {
						return (
							<View key={index} style={styles.Body}>
								<View style={styles.mainTitle}>
									<Text>{item.nome}</Text>
									<Text>total horas {item.totalEmHoras}</Text>
								</View>
								<View style={styles.content}>
									<View style={styles.tableTitle}>
										<Text style={styles.larguraTexto}>data:</Text>
										<Text style={styles.larguraTexto}>Área:</Text>
										<Text style={styles.larguraTexto}>Subdivisão:</Text>
										<Text style={styles.larguraTexto}>Atividade:</Text>
										<Text style={styles.larguraTexto}>Operador</Text>
										<Text style={styles.larguraTexto}>Insumos</Text>
										<Text style={styles.larguraTexto}>quant.(horas)</Text>
									</View>

									{item.atividades.map((subitem, index) => {
										return (
											<View key={index} style={styles.tableContent}>
												<Text style={styles.larguraTexto}>{subitem.data}</Text>
												<Text style={styles.larguraTexto}>{subitem.talhao}</Text>
												<Text style={styles.larguraTexto}>{subitem.subdivisao}</Text>
												<Text style={styles.larguraTexto}>{subitem.atividade}</Text>
												<Text style={styles.larguraTexto}>{subitem.operador}</Text>
												<Text style={styles.larguraTexto}>{subitem.insumos}</Text>
												<Text style={styles.larguraTexto}>{subitem.duracao}</Text>
											</View>
										);
									})}
								</View>
							</View>
						);
					})}

					<View style={styles.footer} fixed>
						<View style={styles.dividerFooter}></View>
						<View style={styles.containerFooter}>
							<Image src={LogoHorizontal} style={styles.logoFooter} />
							<View style={styles.paggingContainer}>
								<View style={styles.paggingText}>
									<Text style={{ fontFamily: "Roboto bold" }}>
										https://sisagri.com.br/
									</Text>
									<Text>
										{date + " - " + hora + " - " + (user?.nome ?? "Não informado")}
									</Text>
								</View>
								<Text
									style={styles.pagnumber}
									render={({ pageNumber }) => `${pageNumber}`}
								/>
							</View>
						</View>
					</View>
				</Page>
			</Document>
			: <Document></Document>
	);
};
export default PdfRelatorioAtividade;
