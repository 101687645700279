import { ReportRepository } from "../repository/report_repository";

export class ReportService {

	constructor() {
		this.reportRepository = new ReportRepository();
	}

	async getFieldNotebookData(params) {
		const notebookFieldData = await this.reportRepository.getFieldNotebookData(params);
		return notebookFieldData;
	}

	async getFieldNotebookGraphicData(params) {
		const notebookFieldData = await this.reportRepository.getFieldNotebookGraphicData(params);
		return notebookFieldData;
	}

	async getProductionBalanceGraphicData(params) {
		const productionBalanceGraphicData = await this.reportRepository.getProductionBalanceGraphicData(params);
		return productionBalanceGraphicData;
	}

	async getProductionBalanceData(params) {
		const productionBalanceData = await this.reportRepository.getProductionBalanceData(params);
		return productionBalanceData;
	}

	async getActivityData(params) {
		const activityData = await this.reportRepository.getActivityData(params);
		return activityData;
	}

	async getSalesData(params) {
		const salesData = await this.reportRepository.getSalesData(params);
		return salesData;
	}

	async getProductivityGraphicData(params) {
		const productivity = await this.reportRepository.getProductivityGraphicData(params);
		return productivity;
	}

	async getProductivityData(params) {
		const productivity = await this.reportRepository.getProductivityData(params);
		return productivity;
	}

	async getLossByFarmGraphicData(params) {
		const loss = await this.reportRepository.getLossByFarmGraphicData(params);
		return loss;
	}

	async getLossFromFarmGraphicData(params) {
		const loss = await this.reportRepository.getLossFromFarmGraphicData(params);
		return loss;
	}

	async getLossByCropSeasonGraphicData(params) {
		const loss = await this.reportRepository.getLossByCropSeasonGraphicData(params);
		return loss;
	}

	async getLossData(params) {
		const loss = await this.reportRepository.getLossData(params);
		return loss;
	}

	async getIncidentByPlotGraphicData(params) {
		const loss = await this.reportRepository.getIncidentByPlotGraphicData(params);
		return loss;
	}

	async getIncidentBySubdivisionGraphicData(params) {
		const loss = await this.reportRepository.getIncidentBySubdivisionGraphicData(params);
		return loss;
	}

	async getIncidentByCropSeasonGraphicData(params) {
		const loss = await this.reportRepository.getIncidentByCropSeasonGraphicData(params);
		return loss;
	}

	async getIncidentByTypeGraphicData(params) {
		const loss = await this.reportRepository.getIncidentByTypeGraphicData(params);
		return loss;
	}

	async getIncidentData(params) {
		const incidents = await this.reportRepository.getIncidentData(params);
		return incidents;
	}

	async getStockInputOutputGraphicData(params) {
		const stock = await this.reportRepository.getStockInputOutputGraphicData(params);
		return stock;
	}

	async getStockUsedGraphicData(params) {
		const stock = await this.reportRepository.getStockUsedGraphicData(params);
		return stock;
	}

	async getStockData(params) {
		const stock = await this.reportRepository.getStockData(params);
		return stock;
	}

	async getActivityByServiceGraphicData(params) {
		const activities = await this.reportRepository.getActivityByServiceGraphicData(params);
		return activities;
	}

	async getActivityByOperatorGraphicData(params) {
		const activities = await this.reportRepository.getActivityByOperatorGraphicData(params);
		return activities;
	}

	async getSalesByCustomerGraphicData(params) {
		const sales = await this.reportRepository.getSalesByCustomerGraphicData(params);
		return sales;
	}

	async getSalesByProductGraphicData(params) {
		const sales = await this.reportRepository.getSalesByProductGraphicData(params);
		return sales;
	}

	async getGeneralData(params) {
		const generalData = await this.reportRepository.getGeneralData(params);
		return generalData;
	}

	async getFieldNotebookPDF(params) {
		return await this.reportRepository.getFieldNotebookPDF(params);
	}

	async getActivitiesForPeriod(params) {
		return await this.reportRepository.getActivitiesForPeriod(params);
	}
}