/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import ButtonExportChart from "../../ButtonExportChart";
import Chart from "../../Chart";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";

// import "./style.css";
import styles from "./RelatorioAtividade.module.css";
import InputData from "../../InputData";
import { PropertyService } from "../../../service/property_service";
import { ReportService } from "../../../service/report_service";
import { PropertyServiceService } from "../../../service/property_service_service";
import { UserService } from "../../../service/user_service";
import { ManagementService } from "../../../service/management_service";
import { PropertyInputService } from "../../../service/property_input_service";

const subdivisionCategories = {
	PARCELA: "Parcela",
	SETOR: "Setor",
	CANTEIRO: "Canteiro",
	BANCADA: "Bancada",
	BALCAO: "Balcão",
	BLOCO: "Bloco",
	GLEBA: "Gleba",
	QUADRA: "Quadra",
	LOTE: "Lote",
};

function RelatorioAtividades(props) {
	const [disable, setDisable] = useState(true);
	const [allTalhoes, setAllTalhoes] = useState([]);
	const [selectedTalhao, setSelectedTalhao] = useState(-1);
	const [allServices, setAllServices] = useState([]);
	const [valueService, setValueService] = useState(-1);
	const [selectOperador, setSelectOperador] = useState(-1);
	const [allOperators, setAllOperators] = useState([]);
	const [personalizedStartDate, setPersonalizedStartDate] = useState(null);
	const [personalizedFinalDate, setPersonalizedFinalDate] = useState(null);
	const [selectedDateInicio, setSelectedDateInicio] = useState("");
	const [selectedDateFim, setSelectedDateFim] = useState("");
	const [selectedSubdivisao, setSelectedSubdivisao] = useState(-1);
	const [allManejos, setAllManejos] = useState([]);
	const [allSubdivisoes, setAllSubdivisoes] = useState([]);
	const [dataPorAtividade, setDataPorAtividade] = useState([
		["Serviço", "Por Serviço"],
		["Serviço", 0]
	]);
	const [dataPorOperador, setDataPorOperador] = useState([
		["Serviço", "Por Servico"],
		["Serviço", 0]
	]);
	const propertyService = new PropertyService();
	const propertyServiceService = new PropertyServiceService();
	const propertyInputService = new PropertyInputService();
	const managementService = new ManagementService();
	const userService = new UserService();
	const reportService = new ReportService();

	const handleDateChangeInicio = (date) => {
		setSelectedDateInicio(new Date(date).toLocaleDateString("pt-BR"));
		setDataInico(new Date(date).toLocaleDateString("pt-BR"));
	};
	const handleDateChangeFim = (date) => {
		setSelectedDateFim(new Date(date).toLocaleDateString("pt-BR"));
		setDataFim(new Date(date).toLocaleDateString("pt-BR"));
	};

	useEffect(() => {
		initData();
	}, []);

	useEffect(() => {
		const startDate = getFormatDate(getStartDate());
		const finalDate = getFormatDate(getFinalDate());

		if(startDate && finalDate && String(selectedTalhao) !== "-1" && String(selectOperador) !== "-1")
			activitiesByService(selectedTalhao, selectOperador, valueService, startDate, finalDate);

		if(startDate && finalDate && String(selectedTalhao) !== "-1" && String(valueService) !== "-1")
			activitiesByOperator(selectedTalhao, valueService, startDate, finalDate);
	}, [
		personalizedStartDate,
		personalizedFinalDate,
		valueService,
		selectedTalhao,
		selectOperador,
	]);

	const activitiesByService = async (selectedTalhao, selectOperador, valueService, startDate, finalDate) => {
		const params = {
			plot: selectedTalhao,
			operator: selectOperador,
			propertyService: String(valueService) !== "-1" ? valueService : null,
			startDate: startDate,
			finalDate: finalDate
		};

		const activitiesData = await reportService.getActivityByServiceGraphicData(params);
		let newData = [dataPorAtividade[0]];
		for (const key in activitiesData) newData.push([key, activitiesData[key]]);

		if(newData.length > 1){
			setDataPorAtividade(newData);
		}else {
			setDataPorAtividade([
				["Serviço", "Por Serviço"],
				["Serviço", 0]
			]);
		}
	};

	const activitiesByOperator = async (selectedTalhao, valueService, startDate, finalDate) => {
		const params = {
			plot: selectedTalhao,
			propertyService: String(valueService) !== "-1" ? valueService : null,
			startDate: startDate,
			finalDate: finalDate
		};

		const activitiesData = await reportService.getActivityByOperatorGraphicData(params);

		let newData = [dataPorOperador[0]];
		for (const key in activitiesData) newData.push([key, activitiesData[key]]);

		if(newData.length > 1){
			setDataPorOperador(newData);
		}else {
			setDataPorOperador([
				["Serviço", "Por Serviço"],
				["Serviço", 0]
			]);
		}
	};

	const getFormatDate = (date) => {
		let data = date.split("/");
		return `${data[2]}-${data[1]}-${data[0]}`;
	};

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const generalParams = {
			page: 1,
			limit: 100000,
		};

		const usersInProperty = await userService.getByProperty(propertyId);
		setOperators(usersInProperty);
		const servicesInProperty = await propertyServiceService.get(propertyId, generalParams);
		setAllServices(await getServicesDetails(servicesInProperty));
		const plotsInProperty = await propertyService.getPlots(propertyId);
		const plots = plotsInProperty.map((plot) => {
			return {...plot, subdivisoes: getSubdivisions(plot.subdivisoes)};
		});

		setAllTalhoes(plots);

		const params = {
			page: 1,
			limit: 100000,
			filterValue: encodeURI(JSON.stringify({ pendente: false }))
		};
		const managements = await managementService.getByProperty(propertyId, params);
		setAllManejos(await getManejoDetails(managements.managements));
	};

	const getSubdivisions = (subdivisions) => {
		if(!subdivisions || subdivisions.length === 0)
			return [];
		
		let disponibleSubdivisions = [];

		subdivisions.forEach((subdivision) => {
			const disponibleChildren = getSubdivisions(subdivision.subdivisoes);

			disponibleChildren.forEach(child => {
				child = {
					nome: `${subdivisionCategories[subdivision.categoriaSubdivisao]} ${subdivision.complemento} - ${child.nome}`,
					id: child.id
				};
				disponibleSubdivisions.push(child);
			});
			
			subdivision = {
				nome: `${subdivisionCategories[subdivision.categoriaSubdivisao]} ${subdivision.complemento}`,
				id: subdivision.id
			};
			disponibleSubdivisions.push(subdivision);
		});

		return disponibleSubdivisions;
	};

	const setOperators = (usersInProperty) => {
		const operators = [];
		if (usersInProperty && usersInProperty !== undefined) {
			for (let i = 0; i < usersInProperty.length; i++) {
				const user = usersInProperty[i].usuario;
				operators.push({ id: user.id, nome: user.nome });
			}
		}
		setAllOperators(operators);
		return operators;
	};

	const getManejoDetails = async (manejos) => {
		const manejosWithDetails = [];
		for (let i = 0; i < manejos.length; i++) {
			const manejo = manejos[i];
			const safra = manejo.safra;
			const talhao = safra.talhao;
			let produtos = "";
			let quantity = "";
			const propertyInputs = manejo.propriedadeInsumos;
			for (let j = 0; j < propertyInputs.length; j++) {
				const propertyInput = await propertyInputService.getById(propertyInputs[j].propriedadeInsumoId);
				const insumo = propertyInput.insumoEmGrupo.insumo;
				produtos += insumo.nome + (j < propertyInputs.length - 1 ? ", " : "");
				quantity +=
					propertyInput.quantidade + (j < propertyInputs.length - 1 ? ", " : "");
			}
			if (manejo.quantidadeVolumeCalda)
				quantity += `/ ${manejo.quantidadeVolumeCalda}`;
			const propertyServico = manejo.propriedadeServico;
			const servico = propertyServico ? propertyServico.servico : null;
			const date = new Date(manejo.dataAplicacao);
			const usuario = await userService.getById(manejo.responsavel);

			let newManejo = {
				id: manejo.id,
				talhaID: talhao.id,
				servicoID: servico.id,
				itemId: manejo.id,
				insumo: produtos !== "" ? produtos : "Não aplicado",
				servico: servico.nome,
				quantidade: quantity !== "" ? quantity : "Não se aplica",
				data: date,
				operadorID: manejo.responsavel,
				operador:
					usuario !== null && usuario !== undefined ? usuario.nome : "não definido",
			};
			manejosWithDetails.push(newManejo);
		}

		return manejosWithDetails;
	};
	
	const getServicesDetails = async (servicesInProperty) => {
		const services = [];
		for (let i = 0; i < servicesInProperty.length; i++) {
			const serviceInProperty = servicesInProperty[i];
			const service = serviceInProperty.servico;
			services.push({ id: serviceInProperty.id, service: service });
		}
		return services;
	};

	const getStartDate = () => {
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth() + 1;
		return (
			personalizedStartDate ??
			`${currentDate.getDate().toString().padStart(2, "0")}/${currentMonth
				.toString()
				.padStart(2, "0")}/${currentDate.getFullYear()}`
		);
	};

	function setTalhao(e) {
		const plot = allTalhoes.find((plotToCheck) => plotToCheck.id === e);
		console.log(plot);
		if(plot)
			setAllSubdivisoes(plot.subdivisoes);
		setSelectedTalhao(e);
	}

	const getFinalDate = () => {
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth() + 1;
		return (
			personalizedFinalDate ??
			`${currentDate.getDate().toString().padStart(2, "0")}/${currentMonth
				.toString()
				.padStart(2, "0")}/${currentDate.getFullYear()}`
		);
	};

	function setDataInico(e) {
		if (e !== "" && selectedDateFim !== "") setDisable(false);
		else setDisable(true);

		setPersonalizedStartDate(e);
	}

	function setDataFim(e) {
		if (selectedDateInicio !== "" && e !== "") setDisable(false);
		else setDisable(true);

		setPersonalizedFinalDate(e);
	}

	function filtroPdf() {
		return `/pdf/?pag=Atividades&index=${
			props.index
		}&responsaveisId=${selectOperador}&talhaoId=${selectedTalhao}&parcelaId=${selectedSubdivisao}&servicoId=${valueService}&startDate=${getStartDate()}&finalDate=${getFinalDate()}`;
	}

	return (
		<Box component="main" sx={{ display: "flex", p: 0 }}>
			<Box sx={{ minWidth: "90vw", maxWidth: "95vw", p: 0 }}>
				<Box sx={{ width: "100%", p: 3, m: 0 }}>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 0, sm: 2, md: 3 }}
						sx={{
							width: "100%",
							m: 0,
							p: 3,
							borderRadius: "8px",
							bgcolor: "white",
						}}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Grid
								container
								rowSpacing={1}
								columnSpacing={{ xs: 0, sm: 2, md: 3 }}
								sx={{
									p: 0,
									display: "flex",
									flexDirection: { xs: "column-reverse", sm: "row" },
								}}
							>
								<Grid item xs={12} sm={6} md={8}>
									<Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Atividade:</p>
											<select
												onChange={(e) => setValueService(e.target.value)}
												value={valueService}
												className={styles.Select}
											>
												<option value={-1}>Todos</option>
												{allServices.map((item, index) => {
													return (
														<option value={item.id} key={index}>
															{item.service.nome}
														</option>
													);
												})}
											</select>
										</Grid>
										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Local:</p>
											<select
												onChange={(e) => setTalhao(e.target.value)}
												value={selectedTalhao}
												className={styles.Select}
											>
												<option value={-1}>Todos</option>
												{allTalhoes.map((item, index) => {
													return (
														<option value={item.id} key={index}>
															Talhao {item.nome}
														</option>
													);
												})}
											</select>
										</Grid>
										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Parcela: </p>
											<select
												onChange={(e) => setSelectedSubdivisao(e.target.value)}
												value={selectedSubdivisao}
												className={styles.Select}
											>
												<option value={-1}>Todos</option>
												{allSubdivisoes.map((item, index) => {
													return (
														<option value={item.id} key={index}>
															{item.nome}
														</option>
													);
												})}
											</select>
										</Grid>
										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Operador:</p>
											<select
												className={styles.Select}
												value={selectOperador}
												onChange={(e) => setSelectOperador(e.target.value)}
											>
												<option value={-1}>Operador</option>
												{allOperators.map((item, index) => {
													return (
														<option value={item.id} key={index}>
															{item.nome}
														</option>
													);
												})}
											</select>
										</Grid>

										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Data de início:</p>
											<InputData
												placeholder="Data de início"
												onDateChange={handleDateChangeInicio}
												classN={styles.Select}
											/>
										</Grid>
										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Data de finalização:</p>
											<InputData
												classN={styles.Select}
												placeholder="Data de finalização"
												onDateChange={handleDateChangeFim}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={4}
									sx={{
										display: "flex",
										justifyContent: { sx: "start", sm: "end" },
										width: "100%",
										px: 1,
									}}
								>
									<ButtonExportChart disabled={disable} href={filtroPdf()} />
								</Grid>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={6}
							sx={{
								display: "flex",
								alignItems: "stretch",
								width: "100%",
								minHeight: "50vh",
							}}
						>
							<Chart
								data={dataPorAtividade}
								options={props.options}
								chartType="ColumnChart"
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={6}
							sx={{
								display: "flex",
								alignItems: "stretch",
								width: "100%",
								minHeight: "50vh",
							}}
						>
							<Chart
								data={dataPorOperador}
								options={props.options}
								chartType="ColumnChart"
							/>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Box>
	);
}

export default RelatorioAtividades;
