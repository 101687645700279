import React, { useEffect, useState } from "react";
import InputSearch from "../../components/SearchHeader/inputSeach";
import TopMenuAdmin from "../../components/TopMenuAdmin";
import "../CommonStyle/style.css";
import * as Button from "../../components/Buttons";
import * as ExcelJS from "exceljs";

//import components
import Search from "../../components/SearchHeader";
import Table from "../../components/TableGenerator";

//material ul imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { captalizeFirstLetterEacheWord, getComparator, stableSort } from "../../utils/utils";
import Loading from "../../components/Loading";
import { GroupInputService } from "../../service/group_input_service";
const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

//column
const columns =[
	{
		id: "group_input",
		label: "Nome do grupo",
	},
	{
		id: "createdAt",
		label: "Data de criação",
	},

];

function AdminGroupInput() {
	const [search, setSearch] = useState("");
	const [groups, setGroups] = useState([]);
	const [allGroups, setAllGroups] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const groupInputService = new GroupInputService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const allGroups = await groupInputService.get();
		const groupsToShow = allGroups.map((group) => {
			return {
				id: group.id,
				group_input: captalizeFirstLetterEacheWord(group.nome),
				createdAt: new Date(group.createdAt).toLocaleDateString("pt-BR")
			};
		});
		setAllGroups(groupsToShow);
		setIsLoading(false);
		setGroups(
			stableSort(groupsToShow, getComparator("asc", columns[0].id))
				.slice(0,7));
	};

	const updateData = async (page, rowsPerPage, orderBy, order) => {
		setGroups(stableSort(allGroups, getComparator(order, orderBy)).slice(
			page * rowsPerPage,
			page * rowsPerPage + rowsPerPage
		));
	};

	const dataFilter = groups.filter((item) =>
		JSON.stringify(item).toLowerCase().includes(search.toLowerCase())
	);
	function handleClick(e) {
		setSearch(e.target.value);
	}

	const handleDelete = async (id) => {
		await groupInputService.changeVisibility(id);
		await initData();
	};

	const custonOp = [
		{
			item: "Insumos",
			func: function handleOpen(id) {
				window.location = `./grupo-insumos/${id}/insumos`;
			},
		},
	];

	const handleExport = async () => {
		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet("Lista de insumos");

		// Chame a função para obter os dados
		const data = await createExcelStructure();

		let excelColumns = [];

		for(let i = 0; i < allGroups.length; i++){
			excelColumns.push({
				header: allGroups[i].group_input,
				key: `coluna${i+1}`,
			});
		}

		// Defina os cabeçalhos das colunas
		worksheet.columns = excelColumns;

		// Adicione os dados na planilha
		worksheet.addRows(data);

		// Crie o arquivo Excel
		workbook.xlsx.writeBuffer().then((buffer) => {
			const blob = new Blob([buffer], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			});
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.href = url;
			a.download = "lista_insumos.xlsx";
			a.click();
		});
	};

	const createExcelStructure = async () => {
		let newData = [];
		if(allGroups.length > 0) {
			let inputs = [];
			for(let i = 0; i < allGroups.length; i++){
				const group = allGroups[i];
				
				const groupInputs = await groupInputService.getInputByGroup(group.id);
				inputs.push(groupInputs);
			}

			const maxArrayIndex = inputs.reduce((maxIndex, currentArray, currentIndex, array) => {
				return currentArray.length > array[maxIndex].length ? currentIndex : maxIndex;
			}, 0);

			for(let i = 0; i < inputs[maxArrayIndex].length; i++){
				let dataRow = {};
				for(let j = 0; j < allGroups.length; j++)
					dataRow[`coluna${j+1}`] = inputs[j].length > i ? inputs[j][i].nome : "";

				newData.push(dataRow);
			}
		}
		
		return newData;
	};

	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<div>
				<TopMenuAdmin open={["admin", "inputs"]}/>
				<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
					<Box sx={{ width: "100%", px: 3 }}>
						<Grid
							container
							rowSpacing={1}
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						>
							<Grid item xs={12} sm={12} md={12}>
								<Item
									sx={{
										bgcolor: "rgba(0, 0, 0, 0)",
										border: "none",
										boxShadow: "none",
									}}
								>
									<Search
										title="Grupos de Insumos"
										href="/admin/grupo-insumos/cadastro"
										textButton="Novo Grupo"
										extraButton={
											<Button.ExportButtonGreen
												funcClick={handleExport}
												text={"Exportar Insumos"}
											/>
										}
									>
										<InputSearch func={handleClick} />
									</Search>
								</Item>
							</Grid>
							<Grid item xs={12}>
								<Item
									sx={{
										bgcolor: "rgba(0, 0, 0, 0)",
										border: "none",
										boxShadow: "none",
									}}
								>
									<Table
										listSize={allGroups.length}
										data={dataFilter}
										columns={columns}
										handleDelete={handleDelete}
										editHref="/admin/grupo-insumos/cadastro?id="
										custonOpition={custonOp}
										updateData={updateData}
										// onclickRow={"/culturas/Embalagens"}
									/>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</div>
		);
}

export default AdminGroupInput;
