import React, { useState } from "react";
import InputSearch from "../../components/SearchHeader/inputSeach";

//import css styles
import styles from "./Business.module.css";

//import components
import Search from "../../components/SearchHeader";

//material ul imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useTheme, createTheme, ThemeProvider } from "@mui/material";
import * as locales from "@mui/material/locale";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

import TableActive from "../../components/TableAdminBusiness/TableBusinessActive";
import TableFinalized from "../../components/TableAdminBusiness/TableBusinessFinalized";
import TableTestFinalized from "../../components/TableAdminBusiness/TableBusinessTestFinalized";
import TableTesting from "../../components/TableAdminBusiness/TableBusinessTesting";
// import Loading from "../../components/Loading";
// import { BusinessService } from "../../service/business_service";
// import { getDateWithoutTimezone } from "../../utils/utils";
import TopMenuAdmin from "../../components/TopMenuAdmin";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

const stylesItem = {
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "start",

	p: 0,
};

//column
// const columns = [
// 	{
// 		id:"razaoSocial",
// 		label: "Razão social",
// 	},
// 	{
// 		id: "nomeFantasia",
// 		label: "Nome fantasia",
// 	},
// 	{
// 		id: "licenca",
// 		label: "Período da Licença",
// 	}
// ];
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 0, width: "auto" }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

function Business() {
	const theme = useTheme();

	const themeWithLocale = React.useMemo(
		() => createTheme(theme, locales["ptBR"]),
		["ptBR", theme]
	);

	const [search, setSearch] = useState("");
	const [value, setValue] = React.useState(0);
	// const [companies, setCompanies] = useState([]);
	// const [totalBusinessData, setTotalBusinessData] = useState(0);
	// const [isLoading, setIsLoading] = useState(false);

	// const businessService = new BusinessService();

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	// const updateData = async (page, rowsPerPage) => {
	// 	const params = {
	// 		page: page + 1,
	// 		limit: rowsPerPage,
	// 		filterValue: {},
	// 		sortBy: JSON.stringify([
	// 			{
	// 				nome: "asc"
	// 			}
	// 		]),
	// 	};

	// 	let business = await businessService.get(params);
	// 	setTotalBusinessData(business.totalItems);

	// 	business = business.business.map((businessItem) => {
			
	// 		return {
	// 			id: businessItem.id,
	// 			razaoSocial: businessItem.nome,
	// 			nomeFantasia: businessItem.nome_fantasia ?? "-",
	// 			licenca: businessItem.licensa?.data_inicio && businessItem.licensa?.data_fim ?
	// 				`${getDateWithoutTimezone(businessItem.licensa?.data_inicio).toLocaleDateString("pt-BR")} - ${getDateWithoutTimezone(businessItem.licensa?.data_fim).toLocaleDateString("pt-BR")}`
	// 				: "Sem licença",
	// 		};
	// 	});
	// 	setCompanies(business);
	// 	setIsLoading(false);
	// };

	// const dataFilter = companies.filter((item) =>
	// 	JSON.stringify(item).toLowerCase().includes(search.toLowerCase())
	// );
	function handleClick(e) {
		setSearch(e.target.value);
	}

	// const handleDelete = async (id) => {
	// 	await businessService.changeVisibility(id);
	// };

	// const custonOp = [
	// 	{
	// 		item: "Usuários",
	// 		func: function handleOpen(id) {
	// 			window.location = `./empresas/${id}/usuarios`;
	// 		},
	// 	},
	// ];

	return (
		<div>
			<TopMenuAdmin open={["admin", "business"]} />
			<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
				<Box sx={{ width: "100%", px: 3 }}>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 0, sm: 2, md: 3 }}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Item
								sx={{
									bgcolor: "rgba(0, 0, 0, 0)",
									border: "none",
									boxShadow: "none",
								}}
							>
								<Search
									title="Empresa"
									href="/admin/empresas/cadastro"
									textButton="Nova Empresa"
								>
									<InputSearch func={handleClick} />
								</Search>
							</Item>
						</Grid>

						<Grid item xs={12}>
							<Item
								sx={{
									bgcolor: "rgba(0, 0, 0, 0)",
									border: "none",
									boxShadow: "none",
									p: 0,
									m: 0,
								}}
							>
								<ThemeProvider theme={themeWithLocale}>
									<Grid
										container
										rowSpacing={0}
										columnSpacing={{ xs: 0, sm: 2, md: 3 }}
									>
										<Grid item xs={12} sm={12} md={12}>
											<Item sx={stylesItem}>
												<Tabs
													value={value}
													onChange={handleChange}
													textColor="white"

													// className="tabs"
												>
													<Tab
														className={
															value === 0
																? styles.tab + " " + styles.focuz
																: styles.tab
														}
														label="Ativos"
														{...a11yProps(0)}
														sx={{
															fontSize: { xs: 9, sm: 10, md: 14 },
															width: "25%",
														}}
													/>
													<Tab
														className={
															value === 1
																? styles.tab + " " + styles.focuz
																: styles.tab
														}
														label="Em teste "
														{...a11yProps(1)}
														sx={{
															fontSize: { xs: 9, sm: 10, md: 14 },
															width: "25%",
														}}
													/>
													<Tab
														className={
															value === 2
																? styles.tab + " " + styles.focuz
																: styles.tab
														}
														label="Teste finalizado "
														{...a11yProps(2)}
														sx={{
															fontSize: { xs: 9, sm: 10, md: 14 },
															width: "25%",
														}}
													/>
													<Tab
														className={
															value === 3
																? styles.tab + " " + styles.focuz
																: styles.tab
														}
														label="Contrato encerrado "
														{...a11yProps(3)}
														sx={{
															fontSize: { xs: 9, sm: 10, md: 14 },
															width: "25%",
														}}
													/>
												</Tabs>
											</Item>
										</Grid>
										<Grid item xs={12} sm={12} md={12}>
											<Item sx={stylesItem}>
												<TabPanel
													className={styles.tabPanel}
													value={value}
													index={0}
												>
													<TableActive search={search} />
												</TabPanel>
												<TabPanel
													className={styles.tabPanel}
													value={value}
													index={1}
												>
													<TableTesting search={search} />
												</TabPanel>
												<TabPanel
													className={styles.tabPanel}
													value={value}
													index={2}
												>
													<TableTestFinalized search={search} />
												</TabPanel>
												<TabPanel
													className={styles.tabPanel}
													value={value}
													index={3}
												>
													<TableFinalized search={search} />
												</TabPanel>
											</Item>
										</Grid>
									</Grid>
								</ThemeProvider>
							</Item>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</div>
	);
}

export default Business;
