import React, { useEffect, useState } from "react";

import Seta from "../../../../Assets/images/seta.png";

import {
	Document,
	Font,
	Image,
	Page,
	StyleSheet,
	Text,
	View,
} from "@react-pdf/renderer";

import JsBarcode from "jsbarcode";
import Roboto from "../../../../Assets/fonts/Roboto-Bold.ttf";
import { OrderService } from "../../../../service/order_service";
import { PropertyService } from "../../../../service/property_service";

Font.register({
	family: "Roboto bold",
	format: "truetype",
	src: Roboto,
});

const styles = StyleSheet.create({
	page: {
	},
	body: {
		paddingTop: "5px",
		paddingLeft: "5px",
		paddingRight: "5px",
		width: "100%",
		height: "100%",
		backgroundColor: "white",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	topEtiquetaContainer: {
		width: "100%",
		height: "40px",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		flexDirection: "row",
		padding: "0",
		margin: "0",
	},
	logoEmpressaContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "30px",
		width: "70px",
	},
	codBarContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	bottonEtiquetaContainer: {
		width: "100%",
		height: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		flexDirection: "row",
	},
	bottonRightTextEtiquetaContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "row",
	},
	containerQrcode: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	qrCode: {
		width: "40px",
	},
	codBar: {
		height: "50px",
		width: "100%",
	},
	codBarPlu: {
		width: "150px",
		height: "30px",
	},
	qrCodeContainer: {
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "center",
		color: "black",
	},
	qrCodeContainerTexTitle: {
		fontSize: "84px",
		textTransform: "uppercase",
		fontFamily: "Roboto bold",
	},
	qrCodeContainerTextSubTitle: {
		fontSize: "30px",
	},
	footer: {
		position: "absolute",
		width: "100vw",
		height: "13px",
		bottom: 0,
	},

	containerFooter: {
		width: "100vw",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	paggingContainer: {
		width: "100%",
		backgroundColor: "black",
		height: "10px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-evenly",
		alignItems: "center",
	},
	paggingText: {
		alignItems: "flex-end",
		fontSize: "8px",
		paddingHorizontal: "5px",
		fontFamily: "Roboto bold",
		color: "white",
	},
	imgFooterInvetida: {
		height: "100%",
		width: "auto",
		transform: "scaleX(-1)",
	},
	imgFooter: {
		height: "100%",
		width: "auto",
	},
	// textTransform
	TextBoldTitle: {
		fontFamily: "Roboto bold",
		fontSize: "10px",
	},
	TextBoldTitleRight: {
		fontFamily: "Roboto bold",
		fontSize: "12px",
	},
	TextBoldTitleNumber: {
		fontFamily: "Roboto bold",
		fontSize: "16px",
	},
	TextBoldSubTitle: {
		fontFamily: "Roboto bold",
		fontSize: "7px",
	},
	TextBold: {
		fontFamily: "Roboto bold",
		fontSize: "6px",
	},
	TextNormal: {
		fontSize: "6px",
	},
	TextNormalQrCode: {
		fontSize: "4px",
	},
});

const PdfHangTags16x5 = (props) => {
	const logo = props.logo;

	const orderItemHarvestId = props.item;
	const orderItemId = props.pedido;
	const [codBar1, setCodBar1] = useState("000000000000");
	const [codBar2, setCodBar2] = useState("000000000000");
	const [loading, setLoading] = useState(true);
	const [property, setProperty] = useState(null);
	const [orderItem, setOrderItem] = useState(null);
	const [harvest, setHarvest] = useState(null);

	const barCodeCODE128 = props.codBar1Type;
	const barCodeEAN13 = props.codBar2Type;

	const orderService = new OrderService();
	const propertyService = new PropertyService();

	useEffect(() => {
		initData();
	}, []);

	const formatCpfCnPJ = (value) => {
		if (value.length > 11) {
			value =
				value.substring(0,2) +
				"." +
				value.substring(2, 5) +
				"." +
				value.substring(5, 8) +
				"/" +
				value.substring(8, 12) +
				"." +
				value.substring(12);
		} else {
			value =
				value.substring(0,3) +
				"." +
				value.substring(3,6) +
				"." +
				value.substring(6,9) +
				"-" +
				value.substring(9);
		}
		return value;
	};

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");

		const propertyData = await propertyService.getById(propertyId);
		setProperty(propertyData);

		const orderItemData = await orderService.getOrderItem(orderItemId);
		setOrderItem(orderItemData);

		const harvestData = orderItemData.lotes.find((harvestItem) => harvestItem.loteId === orderItemHarvestId);
		setHarvest(harvestData);

		const barCodeCustomer = harvestData.lote.propriedadeCultivo.codigoDeBarraCultivoClientes.find((barCodeCustomerItem) => barCodeCustomerItem.clienteId === orderItemData.pedido.cliente.id);

		if (barCodeCODE128) {
			if (harvestData.lote.propriedadeCultivo.codigoDeBarra && harvestData.lote.propriedadeCultivo.codigoDeBarra.length < 12) {
				let codeValor = harvestData.lote.propriedadeCultivo.codigoDeBarra.padStart(12, "0");
				setCodBar1(codeValor);
			} else {
				setCodBar1(harvestData.lote.propriedadeCultivo.codigoDeBarra);
			}
		}
		if (barCodeEAN13 && barCodeCustomer) {
			if (barCodeCustomer.codigoDeBarra && barCodeCustomer.codigoDeBarra.length < 12) {
				let codeValor = barCodeCustomer.codigoDeBarra.padStart(12, "0");
				setCodBar2(codeValor);
			} else {
				setCodBar2(barCodeCustomer.codigoDeBarra);
			}
		}
		setLoading(false);
	};

	function checkValid() {
		return props.checkDist === "0" ? true : false;
	}

	//qrcode
	const ids = props.ids;
	function QRCodePage({ id }) {
		const dataUrl = document.getElementById(id).toDataURL();
		return <Image src={dataUrl} style={styles.qrCode} />;
	}

	//bar code

	function checkDoubleTag() {
		return props.doubleTag === "0" ? true : false;
	}

	const barCodeValorCODE128 = codBar1;
	function validationValueCodBarCODE128() {
		if (barCodeCODE128 === null) return false;
		else return true;
	}
	const barCodeCanvasCODE128 = document.createElement("canvas");
	if(barCodeValorCODE128 && barCodeValorCODE128 !== ""){
		JsBarcode(barCodeCanvasCODE128, barCodeValorCODE128, {
			format: barCodeValorCODE128?.length === 13 ? "EAN13" : "CODE128",
			width: 3,
			fontSize: 20,
			height: 100,
		});
	}
	const barcodeCODE128 = barCodeCanvasCODE128.toDataURL();

	const barCodeValorEAN13 = codBar2;

	function validationValueCodBarEAN13() {
		if (barCodeEAN13 === null) return false;
		else return true;
	}
	const barCodeCanvasEAN13 = document.createElement("canvas");
	if(barCodeValorEAN13 && barCodeValorEAN13 !== ""){
		JsBarcode(barCodeCanvasEAN13, barCodeValorEAN13, {
			format: barCodeValorEAN13?.length === 13 ? "EAN13" : "CODE128",
			width: 3,
			fontSize: 20,
			height: 120,
		});
	}
	const barcodeEAN13 = barCodeCanvasEAN13.toDataURL();
	
	return (
		!loading ?
			<Document>
				<Page size={{ width: 453.39, height: checkDoubleTag() ? 283.32 : 141.66 }} style={styles.page}>
					<View style={{height: checkDoubleTag() ? "50%" : "100%"}}>
						<View style={styles.body}>
							<View style={styles.qrCodeContainer}>
								{/* top etiqueta */}
								<View style={styles.topEtiquetaContainer}>
									<View style={{ height: "100%" }}>
										<View style={styles.logoEmpressaContainer}>
											{logo &&
												<Image style={styles.logo} src={logo} />}
										</View>
									</View>
									<View style={styles.codBarContainer}>
										<Text style={{ fontSize: "12px", textTransform: "uppercase" }}>
											{harvest.lote.propriedadeCultivo.cultivo.cultura + " - " + (harvest.lote.propriedadeCultivo.cultivo.variedade ?? "")}
										</Text>
										{validationValueCodBarCODE128() && codBar1 && (
											<Image style={styles.codBar} src={barcodeCODE128} />
										)}
									</View>
									<View
										style={{
											display: "flex",
											justifyContent: "center",
											alignContent: "center",
											alignItems: "center",
										}}
									>
										{ids.map((id) => (
											<QRCodePage id={id} key={id} />
										))}
									</View>
								</View>
								{/* botton etiqueta */}
								<View style={styles.bottonEtiquetaContainer}>
									{/* esquerda */}
									<View>
										<Text style={styles.TextBoldTitle}>
											Código de Rastreio: {`${orderItem?.pedido?.numeroPedido}-${harvest.lote.codigo}`}
										</Text>
										<Text style={styles.TextBoldTitle}>
											Produzido por {property.nome}{" "}
											{property.cpf_cnpj.length > 11
												? `CNPJ ${formatCpfCnPJ(property.cpf_cnpj)}`
												: `CPF ${formatCpfCnPJ(property.cpf_cnpj)}`}
										</Text>
										<Text style={styles.TextBoldTitle}>
											{property.endereco.endereco + 
												" " + property.endereco.bairro + 
												" - " + property.endereco.cidade}
										</Text>
										
										{checkValid() && (
											<>
												<Text style={styles.TextBoldTitle}>
													Distribuído por {orderItem.pedido.cliente.nome}{" "}
													{orderItem.pedido.cliente.cpf_cnpj.length > 11
														? `CNPJ ${formatCpfCnPJ(orderItem.pedido.cliente.cpf_cnpj)}`
														: `CPF ${formatCpfCnPJ(orderItem.pedido.cliente.cpf_cnpj)}`}
												</Text>
												<Text style={styles.TextBoldTitle}>
													{orderItem.pedido.cliente.enderecoCliente.endereco + 
													" " + orderItem.pedido.cliente.enderecoCliente.bairro + 
													" - " + orderItem.pedido.cliente.enderecoCliente.cidade}
												</Text>
											</>
										)}
										<Text style={styles.TextBoldTitle}>
											{orderItem.data_embalagem &&
												`Data de Embalagem: ${new Date(orderItem.data_embalagem).toLocaleDateString("pt-BR")} - `}
											Data de Entrega: {new Date(orderItem.pedido.data_entrega).toLocaleDateString("pt-BR")}
										</Text>
										<Text style={styles.TextBoldTitle}>{orderItem.especificacao}</Text>
									</View>
									{/* direita */}
									<View>
										<View style={styles.bottonRightTextEtiquetaContainer}>
											<Text style={styles.TextBoldTitleRight}>Peso liq. </Text>
											<Text style={styles.TextBoldTitleNumber}>{`${orderItem?.embalagem.quantidade} ${orderItem?.embalagem.unidadeMedida.sigla}`}</Text>
										</View>
										{validationValueCodBarEAN13() && codBar2 ? (
											<>
												<Image style={styles.codBarPlu} src={barcodeEAN13} />
											</>
										) : (
											""
										)}
									</View>
								</View>
								{/* <View ref={ref} /> */}
							</View>
						</View>
						{/* roda pé  */}
						<View style={styles.footer} fixed>
							<View style={styles.paggingContainer}>
								<Image src={Seta} style={styles.imgFooter} />
								<View style={styles.paggingText}>
									<Text>PRODUTO COM ORIGEM RASTREADA</Text>
								</View>
								<Image src={Seta} style={styles.imgFooterInvetida} />
								<View style={styles.paggingText}>
									<Text>https://www.sisagri.com.br</Text>
								</View>
								<Image src={Seta} style={styles.imgFooterInvetida} />
							</View>
						</View>
					</View>
					{checkDoubleTag() && (
						<View style={{height: "50%"}}>
							<View style={styles.body}>
								<View style={styles.qrCodeContainer}>
									{/* top etiqueta */}
									<View style={styles.topEtiquetaContainer}>
										<View style={{ height: "100%" }}>
											<View style={styles.logoEmpressaContainer}>
												{logo &&
													<Image style={styles.logo} src={logo} />}
											</View>
										</View>

										<View style={styles.codBarContainer}>
											<Text style={{ fontSize: "12px", textTransform: "uppercase" }}>
												{harvest.lote.propriedadeCultivo.cultivo.cultura + " - " + (harvest.lote.propriedadeCultivo.cultivo.variedade ?? "")}	
											</Text>
											{validationValueCodBarCODE128() && codBar1 && (
												<Image style={styles.codBar} src={barcodeCODE128} />
											)}
										</View>
										<View
											style={{
												display: "flex",
												justifyContent: "center",
												alignContent: "center",
												alignItems: "center",
											}}
										>
											{ids.map((id) => (
												<QRCodePage id={id} key={id} />
											))}
										</View>
									</View>
									{/* botton etiqueta */}
									<View style={styles.bottonEtiquetaContainer}>
										{/* esquerda */}
										<View>
											<Text style={styles.TextBoldTitle}>
												Código de Rastreio: {`${orderItem?.pedido?.numeroPedido}-${harvest.lote.codigo}`}
											</Text>
											<Text style={styles.TextBoldTitle}>
												Produzido por {property.nome}{" "}
												{property.cpf_cnpj.length > 11
													? `CNPJ ${formatCpfCnPJ(property.cpf_cnpj)}`
													: `CPF ${formatCpfCnPJ(property.cpf_cnpj)}`}
											</Text>
											<Text style={styles.TextBoldTitle}>
												{property.endereco.endereco + 
													" " + property.endereco.bairro + 
													" - " + property.endereco.cidade}
											</Text>
											
											{checkValid() && (
												<>
													<Text style={styles.TextBoldTitle}>
														Distribuído por {orderItem.pedido.cliente.nome}{" "}
														{orderItem.pedido.cliente.cpf_cnpj.length > 11
															? `CNPJ ${formatCpfCnPJ(orderItem.pedido.cliente.cpf_cnpj)}`
															: `CPF ${formatCpfCnPJ(orderItem.pedido.cliente.cpf_cnpj)}`}
													</Text>
													<Text style={styles.TextBoldTitle}>
														{orderItem.pedido.cliente.enderecoCliente.endereco + 
														" " + orderItem.pedido.cliente.enderecoCliente.bairro + 
														" - " + orderItem.pedido.cliente.enderecoCliente.cidade}
													</Text>
												</>
											)}
											<Text style={styles.TextBoldTitle}>
												{orderItem.data_embalagem &&
													`Data de Embalagem: ${new Date(orderItem.data_embalagem).toLocaleDateString("pt-BR")} - `}
												Data de Entrega: {new Date(orderItem.pedido.data_entrega).toLocaleDateString("pt-BR")}
											</Text>
											<Text style={styles.TextBoldTitle}>{orderItem.especificacao}</Text>
										</View>
										{/* direita */}
										<View>
											<View style={styles.bottonRightTextEtiquetaContainer}>
												<Text style={styles.TextBoldTitleRight}>Peso liq. </Text>
												<Text style={styles.TextBoldTitleNumber}>{`${orderItem?.embalagem.quantidade} ${orderItem?.embalagem.unidadeMedida.sigla}`}</Text>
											</View>
											{validationValueCodBarEAN13() && codBar2 ? (
												<>
													<Image style={styles.codBarPlu} src={barcodeEAN13} />
												</>
											) : (
												""
											)}
										</View>
									</View>
									{/* <View ref={ref} /> */}
								</View>
							</View>
							{/* roda pé  */}
							<View style={styles.footer} fixed>
								<View style={styles.paggingContainer}>
									<Image src={Seta} style={styles.imgFooter} />
									<View style={styles.paggingText}>
										<Text>PRODUTO COM ORIGEM RASTREADA</Text>
									</View>
									<Image src={Seta} style={styles.imgFooterInvetida} />
									<View style={styles.paggingText}>
										<Text>https://www.sisagri.com.br</Text>
									</View>
									<Image src={Seta} style={styles.imgFooterInvetida} />
								</View>
							</View>
						</View>
					)}
				</Page>
			</Document>
			:<Document></Document>
	);
};

export default PdfHangTags16x5;