import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import * as React from "react";
import Chart from "../../components/Chart";
import Climate from "../../components/Climate";
import HomeCalender from "../../components/HomeCalender";
import TopMenu from "../../components/TopMenu";
import "../CommonStyle/style.css";
import "./style.css";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import { ReportService } from "../../service/report_service";
//import ContractModal from "./contractModal.js";

const options = {
	title:
		"Registros de serviços, insumos aplicados e colheitas para os último 3 meses, em unidades.",
	curveType: "function",
	legend: { position: "top" },
};

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

function DrawerAppBar() {
	//const [license, setLicense] = useState(true);
	const [data, setData] = useState([
		[
			"atividade",
			"Serviços Registrados",
			"Insumos Aplicados Registrados",
			"Colheitas Registradas",
		],
		["data", 0, 0, 0],
		["data", 0, 0, 0],
		["data", 0, 0, 0],
	]);
	const [isLoading, setIsLoading] = useState(true);
	const reportService = new ReportService();

	useEffect(() => {
		//getContractLicense();
		let startDate = getStartDate();
		let finalDate = getFinalDate();
		setDataForPeriod(startDate, finalDate);
	}, []);
	
	const getStartDate = () => {
		const currentDate = new Date();
		
		currentDate.setMonth(currentDate.getMonth() - 2);
		return currentDate;
	};

	const getFinalDate = () => {
		const currentDate = new Date();
		
		return currentDate;
	};

	const setDataForPeriod = async (startDate, finalDate) => {
		const propertyId = localStorage.getItem("propertyId");
		let newData = [data[0]];

		let params = {
			propertyId: propertyId,
			startDate: startDate,
			finalDate: finalDate
		};

		let activitiesData = await reportService.getActivitiesForPeriod(params);

		for (const key in activitiesData) {
			newData.push([
				key,
				activitiesData[key].services,
				activitiesData[key].inputs,
				activitiesData[key].harvests,
			]);
		}

		if (newData.length === 1) {
			newData.push(["APLICAÇÃO DE SERVIÇO", 0]);
			newData.push(["APLICAÇÃO DE INSUMO", 0]);
			newData.push(["COLHEITA", 0]);
		}
		
		setData(newData);
		setIsLoading(false);
	};

	/* const getContractLicense = async () => {
		const companyId = localStorage.getItem("company");
		const company = await API.graphql(
			graphqlOperation(queries.getEmpresa, {id: companyId})
		);
		const licensa = await company.data.getEmpresa.LicensaEmpresa;
		//setLicense(licensa.contrato_assinado);
	}; */

	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<>
				{/* license !== true ? <ContractModal/> : <></> */}
				<Box sx={{ display: "flex" }}>
					<TopMenu open={["home", "home"]} />
					<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
						<Box sx={{ width: "100%", px: 3 }}>
							<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
								<Grid
									item
									xs={12}
									sm={12}
									md={7}
									sx={{
										display: "flex",
										alignItems: "stretch",
										// height: "90vh",
									}}
								>
									<Item>
										
										<Grid item xs={12} sm={12} md={12}>
											<HomeCalender />
										</Grid>
										{/* <Grid item xs={12} sm={12} md={12}>
												<Chart data={data} options={options} chartType="ColumnChart" />
											</Grid> */}
									
									</Item>
								</Grid>
								<Grid item
									xs={12}
									sm={12}
									md={5}
									sx={{
										display: "flex",
										alignItems: "stretch",
										height: "100%",
									}}>
									<Grid container rowSpacing={1} columnSpacing={0} sx={{
										width: "100%",
										height: "100%",
									}}>
										<Grid item
											xs={12}
											sm={12}
											md={12}
											sx={{
												display: "flex",
												alignItems: "stretch",
												minHeight: "40vh",
												// height: "100%",
											}}>
											<Chart data={data} options={options} chartType="ColumnChart" />
										</Grid>
										<Grid item xs={12} sm={12} md={12} s>
											<Item sx={{height:"100%"}}>
												<Climate />
											</Item>
										</Grid>
									
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Box>
				</Box>
			</>
			
		);
}

DrawerAppBar.propTypes = {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
};

export default DrawerAppBar;
