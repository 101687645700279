import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const dataHelp = [
	//usuario
	{
		identification: "user",
		title: "usuario",
		help: (
			<>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>
							<b>Cadastrar Novo Usuário</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para cadastrar um novo usuário, clique no botão “Usuários” na
							barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>2. Clique no botão “Novo Usuário”.</Typography>
						<Typography>
							3. Preencha os campos com o nome, e-mail ou número de telefone do
							novo usuário.
						</Typography>
						<Typography>4. Habilite as permissões do operador.</Typography>
						<Typography>
							5. Para finalizar, clique em “cadastrar”. O usuário irá receber
							seu login e senha pelo meio de comunicação cadastrado (E-mail ou
							Telefone).
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2a-content"
						id="panel2a-header"
					>
						<Typography>
							<b>Editar Usuário</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para editar os dados de um usuário, clique no botão “Usuários”
							na barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>
							2. Na área de cadastro, identifique o usuário a ser editado e
							clique nos três pontinhos na lateral.
						</Typography>
						<Typography>
							3. Clique em “editar” e modifique as informações.
						</Typography>
						<Typography>
							4. Clique em “cadastrar” para salvar as modificações.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel3a-content"
						id="panel3a-header"
					>
						<Typography>
							<b>Deletar Usuário</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para deletar os dados de um usuário, clique no botão “Usuários”
							na barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>
							2. Na área de cadastro, identifique o usuário a ser deletado e
							clique nos três pontinhos na lateral.
						</Typography>
						<Typography>
							3. Clique em “deletar” e confirme a operação.
						</Typography>
					</AccordionDetails>
				</Accordion>
			</>
		),
	},
	{
		identification: "usersCad",
		title: "Cadastrar Novo Usuário",
		help: (
			<>
				<Typography>
					1. Preencha os campos com o nome, e-mail ou número de telefone do novo
					usuário.
				</Typography>
				<Typography>2. Habilite as permissões do operador.</Typography>
				<Typography>3. Para finalizar, clique em “cadastrar”.</Typography>
			</>
		),
	},
	{
		identification: "userEdit",
		title: "Editr Usuário",
		help: (
			<>
				<Typography>1. Modifique as informações.</Typography>
				<Typography>
					2. Clique em “cadastrar” para salvar as modificações.
				</Typography>
			</>
		),
	},
	//cutura
	{
		identification: "culture",
		title: "Culturas",
		help: (
			<>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>
							<b>Cadastrar nova cultura e variedade</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para cadastrar um nova cultura e variedade, clique no botão
							“Culturas” na barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>2. Clique no botão “Nova Cultura”.</Typography>
						<Typography>
							3. No espaço “selecione Cultura/Variedade”, escolha a cultura e
							variedade a ser cadastrada.
						</Typography>
						<Typography>
							4. Se possuir o código de barras do seu produto, adicione no campo
							“Código de barras”.
						</Typography>
						<Typography>
							5. Em seguida, defina a(s) embalagem (ns) que o produto poderá ser
							destinado clicando no “+”, em “adicionar embalagem”. Informe o
							tipo, a quantidade por embalagem e a unidade de medida.
						</Typography>
						<Typography>6. Clique em “Cadastrar”.</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2a-content"
						id="panel2a-header"
					>
						<Typography>
							<b>Deletar Cultura e Variedade</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para deletar uma cultura e variedade, clique no botão
							“Culturas” na barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>
							2. Na área de cadastro, identifique a cultura e variedade a ser
							deletada e clique nos três pontinhos na lateral.
						</Typography>
						<Typography>
							3. Clique em “deletar” e confirme a operação.
						</Typography>
					</AccordionDetails>
				</Accordion>
			</>
		),
	},
	{
		identification: "cultureCad",
		title: "Cadastrar nova cultura e variedade",
		help: (
			<>
				<Typography>
					1. No espaço “selecione Cultura/Variedade”, escolha a cultura e
					variedade a ser cadastrada.
				</Typography>
				<Typography>
					2. Se possuir o código de barras do seu produto, adicione no campo
					“Código de barras”.
				</Typography>
				<Typography>
					3. Em seguida, defina a(s) embalagem (ns) que o produto poderá ser
					destinado clicando no “+”, em “adicionar embalagem”. Informe o tipo, a
					quantidade por embalagem e a unidade de medida.
				</Typography>
				<Typography>4. Clique em “Cadastrar”.</Typography>
			</>
		),
	},
	//serviços
	{
		identification: "services",
		title: "Serviços",
		help: (
			<>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>
							<b>Cadastro de Novo Serviço</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para cadastrar um novo serviço, clique no botão “Serviços” na
							barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>2. Clique no botão “Novo Serviço”.</Typography>
						<Typography>
							3. Selecione o grupo em que se encaixa e o serviço a ser
							cadastrado.
						</Typography>
						<Typography>
							4. Preencha o campo de “valor por hora” com o dado de custo da
							atividade – Opcional (O dado deste campo irá compor o relatório de
							custos de produção).
						</Typography>
						<Typography>
							5. Preencha o campo “Observações” se fizer sentido na operação –
							Opcional.
						</Typography>
						<Typography>6. Para finalizar, clique em “Cadastrar”.</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2a-content"
						id="panel2a-header"
					>
						<Typography>
							<b>Editar Serviço</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para editar os dados de um serviço, clique no botão “Serviços”
							na barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>
							2. Na área de cadastro, identifique o serviço a ser editado e
							clique nos três pontinhos na lateral.
						</Typography>
						<Typography>
							3. Clique em “editar” e modifique as informações
						</Typography>
						<Typography>
							4. Clique em “cadastrar” para salvar as modificações.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel3a-content"
						id="panel3a-header"
					>
						<Typography>
							<b>Deletar Serviço</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para deletar os dados de um serviço, clique no botão “Serviços”
							na barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>
							2. Na área de cadastro, identifique o serviço a ser deletado e
							clique nos três pontinhos na lateral.
						</Typography>
						<Typography>
							3. Clique em “deletar” e confirme a operação.
						</Typography>
					</AccordionDetails>
				</Accordion>
			</>
		),
	},
	{
		identification: "servicesCad",
		title: "Cadastro de Novo Serviço",
		help: (
			<>
				<Typography>
					1. Selecione o grupo em que se encaixa e o serviço a ser cadastrado.
				</Typography>
				<Typography>
					2. Preencha o campo de “valor por hora” com o dado de custo da
					atividade – Opcional (O dado deste campo irá compor o relatório de
					custos de produção).
				</Typography>
				<Typography>
					3. Preencha o campo “Observações” se fizer sentido na operação –
					Opcional.
				</Typography>
				<Typography>4. Para finalizar, clique em “Cadastrar”.</Typography>
			</>
		),
	},
	{
		identification: "servicesEdit",
		title: "Editar Serviço",
		help: (
			<>
				<Typography>1. Modifique as informações.</Typography>
				<Typography>
					2. Clique em “cadastrar” para salvar as modificações.
				</Typography>
			</>
		),
	},
	//cliente
	{
		identification: "client",
		title: "Cliente",
		help: (
			<>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>
							<b>Cadastro de Novo Cliente</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para cadastrar um novo cliente, clique no botão “Clientes” na
							barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>2. Clique no botão “Novo Cliente”.</Typography>
						<Typography>
							3. Preencha os dados de identificação e localização do cliente.
						</Typography>
						<Typography>
							4. Preencha o campo “Observações” se fizer sentido na operação –
							Opcional.
						</Typography>
						<Typography>5. Para finalizar, clique em “Cadastrar”.</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2a-content"
						id="panel2a-header"
					>
						<Typography>
							<b>Editar Cliente</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para editar os dados de um cliente, clique no botão “Clientes”
							na barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>
							2. Na área de cadastro, identifique o cliente a ser editado e
							clique nos três pontinhos na lateral.
						</Typography>
						<Typography>
							3. Clique em “editar” e modifique as informações.
						</Typography>
						<Typography>
							4. Clique em “cadastrar” para salvar as modificações.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel3a-content"
						id="panel3a-header"
					>
						<Typography>
							<b>Deletar Cliente </b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para deletar os dados de um cliente, clique no botão “Clientes”
							na barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>
							2. Na área de cadastro, identifique o serviço a ser deletado e
							clique nos três pontinhos na lateral.
						</Typography>
						<Typography>
							3. Clique em “deletar” e confirme a operação.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel4a-content"
						id="panel4a-header"
					>
						<Typography>
							<b>Cadastrar Código de Barras do Cliente</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para cadastrar código de barras do cliente, clique no botão
							“Clientes” na barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>
							2. Na área de cadastro, identifique o cliente a ser editado e
							clique nos três pontinhos na lateral.
						</Typography>
						<Typography>
							3. Clique em “código de barras” e preencha os campos de
							cultura/variedade e código.
						</Typography>
						<Typography>
							4. Clique em “cadastrar” para salvar os dados.
						</Typography>
					</AccordionDetails>
				</Accordion>
			</>
		),
	},
	{
		identification: "clientCad",
		title: "Cadastro de Novo Cliente",
		help: (
			<>
				<Typography>
					1. Preencha os dados de identificação e localização do cliente.
				</Typography>
				<Typography>
					2. Preencha o campo “Observações” se fizer sentido na operação –
					Opcional.
				</Typography>
				<Typography>3. Para finalizar, clique em “Cadastrar”.</Typography>
			</>
		),
	},
	{
		identification: "clientEdit",
		title: "Editar Cliente",
		help: (
			<>
				<Typography>1. Modifique as informações.</Typography>
				<Typography>
					2. Clique em “cadastrar” para salvar as modificações.
				</Typography>
			</>
		),
	},
	//insumos
	{
		identification: "insumos",
		title: "Insumos",
		help: (
			<>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>
							<b>Cadastro de Novo Insumo</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para cadastrar um novo insumo, clique no botão “Insumos” na
							barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>2. Clique no botão “Novo Insumo”.</Typography>
						<Typography>
							3. Selecione o grupo ao qual ele se encaixa.
						</Typography>
						<Typography>
							4. Selecione o insumo a ser registrado pela lista.
						</Typography>
						<Typography>
							5. Especifique a unidade de medida do produto (Obs.: O mesmo
							produto com formulações diferentes deve ser registrado
							separadamente especificando sua devida unidade.).
						</Typography>
						<Typography>
							6. Preencha o campo de “Custo Unitário” com o dado de custo da
							unidade de medida do produto (Ex.: Um produto que custa 5 reais/L,
							preencha o espaço com o número 5) – (Opcional).
						</Typography>
						<Typography>
							7. Preencha o campo de “intervalo para nova aplicação” e
							“Carência” – Vide bula do produto e verifique a recomendação da
							fabricante.
						</Typography>
						<Typography>8. Para finalizar, clique em “Cadastrar”.</Typography>
						<Typography>
							Obs.: O dado de custo unitário irá compor o relatório de custos de
							produção, dessa maneira, a ausência deste dado resultará na
							limitação dos relatórios e análises de custos.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2a-content"
						id="panel2a-header"
					>
						<Typography>
							<b>Editar Insumo </b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para editar os dados de um insumo, clique no botão “Insumos” na
							barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>
							2. Na área de cadastro, identifique o insumo a ser editado e
							clique nos três pontinhos na lateral.
						</Typography>
						<Typography>
							3. Clique em “editar” e modifique as informações.
						</Typography>
						<Typography>
							4. Clique em “cadastrar” para salvar as modificações.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel3a-content"
						id="panel3a-header"
					>
						<Typography>
							<b>Deletar Insumo</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para deletar os dados de um Insumo, clique no botão “Insumos”
							na barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>
							2. Na área de cadastro, identifique o insumo a ser deletado e
							clique nos três pontinhos na lateral.
						</Typography>
						<Typography>
							3. Clique em “deletar” e confirme a operação.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel4a-content"
						id="panel4a-header"
					>
						<Typography>
							<b>Exportar lista de insumos</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para exporta a lista de insumos, clique no botão “Insumos” na
							barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>
							2. Na área de cadastro, clique no botão “exportar lista de
							produtos”.
						</Typography>
						<Typography>
							3. Acesse a página de visualização do arquivo e clique em download
							e/ou imprimir.
						</Typography>
					</AccordionDetails>
				</Accordion>
			</>
		),
	},
	{
		identification: "insumosCad",
		title: "Cadastro de Novo Insumo",
		help: (
			<>
				<Typography>1. Selecione o grupo ao qual ele se encaixa.</Typography>
				<Typography>
					2. Selecione o insumo a ser registrado pela lista.
				</Typography>
				<Typography>
					3. Especifique a unidade de medida do produto (Obs.: O mesmo produto
					com formulações diferentes deve ser registrado separadamente
					especificando sua devida unidade.).
				</Typography>
				<Typography>
					4. Preencha o campo de “Custo Unitário” com o dado de custo da unidade
					de medida do produto (Ex.: Um produto que custa 5 reais/L, preencha o
					espaço com o número 5) – (Opcional).
				</Typography>
				<Typography>
					5. Preencha o campo de “intervalo para nova aplicação” e “Carência” –
					Vide bula do produto e verifique a recomendação da fabricante.
				</Typography>
				<Typography>8. Para finalizar, clique em “Cadastrar”.</Typography>
				<Typography>
					Obs.: O dado de custo unitário irá compor o relatório de custos de
					produção, dessa maneira, a ausência deste dado resultará na limitação
					dos relatórios e análises de custos.
				</Typography>
			</>
		),
	},
	{
		identification: "insumosEdit",
		title: "Editar Insumos",
		help: (
			<>
				<Typography>1. Modifique as informações.</Typography>
				<Typography>
					2. Clique em “cadastrar” para salvar as modificações.
				</Typography>
			</>
		),
	},
	//safra
	{
		identification: "safra",
		title: "Safra",
		help: (
			<>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>
							<b>Nova Safra</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para cadastrar uma nova safra, clique no botão “Safras” na
							barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography> 2. Clique no botão “Nova Safra”.</Typography>
						<Typography>
							3. Preencha os campos de data de início, selecione a cultura e
							variedade cultivada, bem como a área e a subdivisão em que a
							cultura foi implantada.
						</Typography>
						<Typography>
							4. Por fim, clique em “Cadastrar” para salvar as informações.
						</Typography>
						<Typography>
							Obs.: Na tela de cadastro o mapa apresenta todas as áreas
							cadastradas e as safras ativas facilitando a visualização do plano
							de cultivo da propriedade.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2a-content"
						id="panel2a-header"
					>
						<Typography>
							<b>Editar Safra</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para editar os dados de uma safra, clique no botão “Safras” na
							barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>
							2. Na área de cadastro, identifique a safra a ser editada e clique
							nos três pontinhos na lateral.
						</Typography>
						<Typography>
							3. Clique em “editar” e modifique as informações.
						</Typography>
						<Typography>
							4. Clique em “cadastrar” para salvar as modificações.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel3a-content"
						id="panel3a-header"
					>
						<Typography>
							<b>Deletar Safra</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para deletar os dados de uma safra, clique no botão “Safras” na
							barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>
							2. Na área de cadastro, identifique a safra a ser deletada e
							clique nos três pontinhos na lateral.
						</Typography>
						<Typography>
							3. Clique em “deletar” e confirme a operação.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel4a-content"
						id="panel4a-header"
					>
						<Typography>
							<b>Finalizar Safras</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para finalizar uma safra, clique no botão “Safras” na barra
							lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>
							2. Na área de cadastro, selecione a safra a ser finalizada. Se
							houver múltiplas safras a serem finalizadas na mesma data,
							selecione todas.
						</Typography>
						<Typography>3. Clique em “Finalizar Safras”.</Typography>
						<Typography>
							4. Selecione a data de finalização da safra no campo e clique em
							“Finalizar”.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel5a-content"
						id="panel5a-header"
					>
						<Typography>
							<b> Visualizar listagem de safras finalizadas</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para visualizar as safras finalizadas, clique no botão “Safras”
							na barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>2. Clique na aba “finalizadas”.</Typography>
					</AccordionDetails>
				</Accordion>
			</>
		),
	},
	{
		identification: "safraCad",
		title: "Nova Safra",
		help: (
			<>
				<Typography>
					1. Preencha os campos de data de início, selecione a cultura e
					variedade cultivada, bem como a área e a subdivisão em que a cultura
					foi implantada.
				</Typography>
				<Typography>
					2. Por fim, clique em “Cadastrar” para salvar as informações.
				</Typography>
				<Typography>
					Obs.: Na tela de cadastro o mapa apresenta todas as áreas cadastradas
					e as safras ativas facilitando a visualização do plano de cultivo da
					propriedade.
				</Typography>
			</>
		),
	},
	{
		identification: "safraEdit",
		title: "Editar Safra",
		help: (
			<>
				<Typography>1. Modifique as informações.</Typography>
				<Typography>
					2. Clique em “cadastrar” para salvar as modificações.
				</Typography>
			</>
		),
	},
	//manejo
	{
		identification: "management",
		title: "Manejo",
		help: (
			<>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>
							<b>Novo Manejo</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para cadastrar um novo manejo, clique no botão “Manejo” na
							barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>2. Clique no botão “Novo Manejo”.</Typography>
						<Typography>
							3. Preencha os campos de data, hora (momento da aplicação) e
							duração da aplicação.
						</Typography>
						<Typography>
							4. Informe a safra em que o manejo será registrado clicando no “+”
							no campo “adicionar safras”. Se houver mais de uma safra, repita o
							passo.
						</Typography>
						<Typography>
							5. Na área de “adicionar produtos” clique no “+” para adicionar o
							insumo associado ao serviço. Especifique o insumo, a quantidade e
							sua unidade de medida. Se a aplicação for uma mistura, repita o
							passo adicionando todos os insumos utilizados.
						</Typography>
						<Typography>
							<b>Se for aplicação de uma calda:</b>
						</Typography>
						<Typography>
							6. Selecione a caixa “Adicionar o volume de calda aos produtos”.
						</Typography>
						<Typography>
							7. Escolha o equipamento da aplicação, especifique o volume de
							calda, modo de aplicação.
						</Typography>
						<Typography>
							8. Selecione o operador que irá a ser destinado a atividade.
						</Typography>
						<Typography>
							9. Por fim, clique em cadastrar para salvar o manejo.
						</Typography>
						<Typography>
							<b>Obs.:</b>

							<Typography>
								1. Em caso de manejo sem produto associado (Ex.: Capina), não é
								necessário preencher os campos relacionados à adição de
								produtos.
							</Typography>
							<Typography>
								2. O registro de manejo em múltiplas safras apenas é possível se
								as variáveis da aplicação forem idênticas.
							</Typography>
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2a-content"
						id="panel2a-header"
					>
						<Typography>
							<b>Editar Manejo</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para editar os dados de um manejo, clique no botão “Manejo” na
							barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>
							2. Na área de cadastro, identifique o manejo a ser editado e
							clique nos três pontinhos na lateral.
						</Typography>
						<Typography>
							3. Clique em “editar” e modifique as informações.
						</Typography>
						<Typography>
							4. Clique em “cadastrar” para salvar as modificações.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel3a-content"
						id="panel3a-header"
					>
						<Typography>
							<b>Deletar Manejo</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para deletar os dados de um manejo, clique no botão “Manejo” na
							barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>
							2. Na área de cadastro, identifique o manejo a ser deletado e
							clique nos três pontinhos na lateral.
						</Typography>
						<Typography>
							3. Clique em “deletar” e confirme a operação.
						</Typography>
					</AccordionDetails>
				</Accordion>
			</>
		),
	},
	{
		identification: "managementCad",
		title: "Novo Manejo",
		help: (
			<>
				<Typography>
					1. Preencha os campos de data, hora (momento da aplicação) e duração
					da aplicação.
				</Typography>
				<Typography>
					2. Informe a safra em que o manejo será registrado clicando no “+” no
					campo “adicionar safras”. Se houver mais de uma safra, repita o passo.
				</Typography>
				<Typography>
					3. Na área de “adicionar produtos” clique no “+” para adicionar o
					insumo associado ao serviço. Especifique o insumo, a quantidade e sua
					unidade de medida. Se a aplicação for uma mistura, repita o passo
					adicionando todos os insumos utilizados.
				</Typography>
				<Typography>
					<b>Se for aplicação de uma calda:</b>
				</Typography>
				<Typography>
					4. Selecione a caixa “Adicionar o volume de calda aos produtos”.
				</Typography>
				<Typography>
					5. Escolha o equipamento da aplicação, especifique o volume de calda,
					modo de aplicação.
				</Typography>
				<Typography>
					6. Selecione o operador que irá a ser destinado a atividade.
				</Typography>
				<Typography>
					7. Por fim, clique em cadastrar para salvar o manejo.
				</Typography>
				<Typography>
					<b>Obs.:</b>

					<Typography>
						1. Em caso de manejo sem produto associado (Ex.: Capina), não é
						necessário preencher os campos relacionados à adição de produtos.
					</Typography>
					<Typography>
						2. O registro de manejo em múltiplas safras apenas é possível se as
						variáveis da aplicação forem idênticas.
					</Typography>
				</Typography>
			</>
		),
	},
	{
		identification: "managementEdit",
		title: "Editar Manejo",
		help: (
			<>
				<Typography>1. Modifique as informações.</Typography>
				<Typography>
					2. Clique em “cadastrar” para salvar as modificações.
				</Typography>
			</>
		),
	},
	//pedido
	{
		identification: "requests",
		title: "Pedidos",
		help: (
			<>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>
							<b>Novo Pedido</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para cadastrar um novo pedido, clique no botão “Pedido” na barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>2. Clique no botão “Novo Pedido”;</Typography>
						<Typography>
							3. Selecione o cliente, informe a data de expedição e o código de nota fiscal (se tiver);
						</Typography>
						<Typography>
							4. Em “Adicionar cultura e variedade”, clique no “+” para informar os dados do produto;
						</Typography>
						<Typography>
							5. Selecione a cultura, informe o preço (Opcional), o tipo de embalagem e a data embalagem;
						</Typography>
						<Typography>
							<b>Se tiver produto em estoque:</b>
						</Typography>
						<Typography>
							6. Em “adicionar lote”, clique no “+” para selecionar o lote de produto que será vendido;
						</Typography>
						<Typography>
							7. Selecione o lote e especifique a quantidade de produto;
						</Typography>
						<Typography>
							8. Clique no “+” para registrar o produto estocado no pedido.
						</Typography>
						<Typography>
							9. Por fim, clique em cadastrar para registrar o produto no pedido;
						</Typography>
						<Typography>
							10. Repita o processo para quantos produtos for necessário;
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2a-content"
						id="panel2a-header"
					>
						<Typography>
							<b>Editar Pedido</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Na área de cadastro, identifique o pedido a ser editado e clique nos “3 pontinhos” (opções) na lateral.
						</Typography>
						<Typography>
							2. Clique em editar e modifique e/ou adicione as informações. No espaço de edição é possível editar as informações do pedido, editar os itens do pedido, bem como adicionar mais itens (produtos) em pedido.
						</Typography>
						<Typography>
							3. Clique em “cadastrar” para salvar as modificações.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel3a-content"
						id="panel3a-header"
					>
						<Typography>
							<b>Deletar Pedido</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Na área de cadastro, identifique o pedido a ser deletado e clique nos três pontinhos na lateral;
						</Typography>
						<Typography>
							2. Clique em deletar. Irá aparecer uma notificação: “Deseja deletar o pedido?”.
						</Typography>
						<Typography>
							3. Clique em “deletar” e confirme a operação;
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel4a-content"
						id="panel4a-header"
					>
						<Typography>
							<b>Registrar Devolução de Pedido</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para registrar devolução de um pedido, clique no botão “Pedido” na barra lateral para entrar no espaço de cadastro;
						</Typography>
						<Typography>
							2. Na área de cadastro, identifique o pedido a ser editado e clique nos três pontinhos.
						</Typography>
						<Typography>
							3. Clique em editar.
						</Typography>
						<Typography>
							4. Encontre o produto a ser devolvido no pedido e clique nos três pontinhos na lateral;
						</Typography>
						<Typography>5. Clique em “devolução”;</Typography>
						<Typography>
							6. No campo “Devolução” registre o motivo da devolução e escolha qual o destino do produto: Retornar para estoque ou Registrar Perda;
						</Typography>
						<Typography>
							7. Se retornar para estoque o produto irá para o estique de produtos e poderá ser repassado para outro destino;
						</Typography>
						<Typography>
							8. Se registrar como perda, você será redirecionado para a aba de perdas. Preencha os campos e finalize a operação clicando em “Cadastrar”.
						</Typography>
						<Typography>
							<b>Obs.:</b>
						</Typography>
						<Typography>
							A devolução de pedidos deve ser utilizada em caso de retorno de carga ou remanejo de destino;
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel5a-content"
						id="panel5a-header"
					>
						<Typography>
							<b>Gerar Romaneio de Vendas</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1.Para gerar o seu romaneio de vendas, clique no botão “Pedido” na barra lateral para entrar no espaço de cadastro;
						</Typography>
						<Typography>
							2. Na área de cadastro, identifique o pedido e clique nos três pontinhos na lateral;
						</Typography>
						<Typography>3. Clique em “Romaneio”;</Typography>
						<Typography>4. Exporte ou imprima seu romaneio;</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel6a-content"
						id="panel6a-header"
					>
						<Typography>
							<b> Gerar Etiqueta</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para gerar a etiqueta de identificação de um produto, clique no botão “Pedido” na barra lateral para entrar no espaço de cadastro;
						</Typography>
						<Typography>
							2. Na área de cadastro, identifique o pedido onde o produto está destinado e clique os três pontinhos.
						</Typography>
						<Typography>
							3. Clique em “Etiquetas”.
						</Typography>
						<Typography>
							4. No campo de etiquetas você visualizará um campo de pedido com o pedido em questão selecionado, o campo de lote com os produtos a serem etiquetados. Selecione um produto e siga para especificar o modelo de etiquetas.
						</Typography>
						<Typography>5. Escolha seu modelo de etiqueta e informe se haverá códigos de barras. Você também tem a possibilidade de adicionar informações do seu cliente e optar por uma impressão com a etiqueta duplicada;</Typography>
						<Typography>6. Clique em “gerar etiqueta”</Typography>
						<Typography>7. Exporte ou imprima sua etiqueta;</Typography>
					</AccordionDetails>
				</Accordion>
			</>
		),
	},

	{
		identification: "requestsItemDetelhes",
		title: "Pedidos",
		help: (
			<>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>
							<b>Novo Pedido</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para cadastrar um novo pedido, clique no botão “Pedido” na
							barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>2. Clique no botão “Novo Pedido”.</Typography>
						<Typography>
							3. Selecione o cliente, informe a data de entrega.
						</Typography>
						<Typography>
							4. Em “Adicionar cultura e variedade”, clique no “+” para informar
							os dados do produto.
						</Typography>
						<Typography>
							5. Selecione a cultura, informe o preço (Opcional), a quantidade
							do produto, a embalagem de destinação e o operador responsável
							pela colheita do produto.
						</Typography>
						<Typography>
							<b>Se tiver produto em estoque:</b>
						</Typography>
						<Typography>
							6. Se tiver produto em estoque, considere registrar a venda dessa
							quantidade estocada. Clique na caixa “Utilizar produto em
							estoque”.
						</Typography>
						<Typography>
							7. Identifique o lote do produto e a quantidade.
						</Typography>
						<Typography>
							8. Clique no “+” para registrar o produto estocado no pedido.
						</Typography>
						<Typography>
							9. Por fim, clique em cadastrar para registrar o produto no
							pedido.
						</Typography>
						<Typography>
							10. Repita o processo para quantos produtos for necessário.
						</Typography>
						<Typography>
							<b>Obs.:</b>
						</Typography>
						<Typography>
							Caso a quantidade em estoque não for o suficiente para satisfazer
							o pedido, ajuste em “quantidade” a quantidade a ser vendida. (Ex.:
							Se possui um pedido de 50 kg de laranja e possui apenas 30 kg em
							estoque, registre em “quantidade” 20 kg, totalizando 50 kg).
						</Typography>
						<Typography>
							Caso a quantidade estocada satisfaça a necessidade do pedido,
							preencha o campo “quantidade” com 0 ou deixe em braço..
						</Typography>
						<Typography>
							O registro de pedido está diretamente relacionado com a aplicação
							de colheita e com estoque.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2a-content"
						id="panel2a-header"
					>
						<Typography>
							<b>Editar Pedido</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Encontre o produto a ser editado no pedido e clique nos três
							pontinhos na lateral.
						</Typography>
						<Typography>
							2. Clique em editar e modifique as informações.
						</Typography>
						<Typography>
							3. Clique em “cadastrar” para salvar as modificações.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel3a-content"
						id="panel3a-header"
					>
						<Typography>
							<b>Deletar Pedido</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Encontre o produto a ser editado no pedido e clique nos três
							pontinhos na lateral.
						</Typography>
						<Typography>
							2. Clique em “deletar” e confirme a operação.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel4a-content"
						id="panel4a-header"
					>
						<Typography>
							<b>Registrar Devolução de Pedido</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Encontre o produto a ser devolvido no pedido e clique no três
							pontinhos na lateral.
						</Typography>
						<Typography>5. Clique em “devolução”.</Typography>
						<Typography>
							2. No campo “Devolução” registre o motivo da devolução e escolha
							qual o destino do produto: Retornar para estoque ou Registrar
							Perda.
						</Typography>
						<Typography>
							3. Se retornar para estoque o produto irá para o estique de
							produtos e poderá ser repassado para outro destino.
						</Typography>
						<Typography>
							4. Se registrar como perda, você será redirecionado para a aba de
							perdas. Preencha os campos e finalize a operação clicando em
							“Cadastrar”.
						</Typography>
						<Typography>
							<b>Obs.:</b>
						</Typography>
						<Typography>
							A devolução de pedidos deve ser utilizada em caso de retorno de
							carga ou remanejo de destino.
						</Typography>
					</AccordionDetails>
				</Accordion>

				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel5a-content"
						id="panel5a-header"
					>
						<Typography>
							<b> Gerar Etiqueta</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Encontre o produto a ser identificado e clique em “Gerar
							Etiquetas”.
						</Typography>
						<Typography>2. Exporte ou imprima sua etiqueta.</Typography>
					</AccordionDetails>
				</Accordion>
			</>
		),
	},
	{
		identification: "requestsCad",
		title: "Novo Pedido",
		help: (
			<>
				<Typography>
					1. Selecione o cliente, informe a data de entrega.
				</Typography>
				<Typography>
					2. Em “Adicionar cultura e variedade”, clique no “+” para informar os
					dados do produto.
				</Typography>
				<Typography>
					3. Selecione a cultura, informe o preço (Opcional), a quantidade do
					produto, a embalagem de destinação e o operador responsável pela
					colheita do produto.
				</Typography>
				<Typography>
					<b>Se tiver produto em estoque:</b>
				</Typography>
				<Typography>
					4. Se tiver produto em estoque, considere registrar a venda dessa
					quantidade estocada. Clique na caixa “Utilizar produto em estoque”.
				</Typography>
				<Typography>
					5. Identifique o lote do produto e a quantidade.
				</Typography>
				<Typography>
					6. Clique no “+” para registrar o produto estocado no pedido.
				</Typography>
				<Typography>
					7. Por fim, clique em cadastrar para registrar o produto no pedido.
				</Typography>
				<Typography>
					8. Repita o processo para quantos produtos for necessário.
				</Typography>
				<Typography>
					<b>Obs.:</b>
				</Typography>
				<Typography>
					Caso a quantidade em estoque não for o suficiente para satisfazer o
					pedido, ajuste em “quantidade” a quantidade a ser vendida. (Ex.: Se
					possui um pedido de 50 kg de laranja e possui apenas 30 kg em estoque,
					registre em “quantidade” 20 kg, totalizando 50 kg).
				</Typography>
				<Typography>
					Caso a quantidade estocada satisfaça a necessidade do pedido, preencha
					o campo “quantidade” com 0 ou deixe em braço..
				</Typography>
				<Typography>
					O registro de pedido está diretamente relacionado com a aplicação de
					colheita e com estoque.
				</Typography>
			</>
		),
	},
	{
		identification: "requestsEdit",
		title: "Editar Pedido",
		help: (
			<>
				<Typography>1. Modifique as informações.</Typography>
				<Typography>
					2. Clique em “cadastrar” para salvar as modificações.
				</Typography>
			</>
		),
	},
	//perdas
	{
		identification: "losses",
		title: "Perdas",
		help: (
			<>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>
							<b> Nova Perda</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para cadastrar uma nova perda, clique no botão “Perdas” na
							barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>2. Clique no botão “Nova perda”.</Typography>
						<Typography>3. Selecione o tipo de perda.</Typography>
						<Typography>
							4. Selecione a safra ou o lote onde foi identificado a perda.
						</Typography>
						<Typography>
							5. Especifique a data, quantidade perdida e descrição.
						</Typography>
						<Typography>6. Por fim, clique em” cadastrar”.</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2a-content"
						id="panel2a-header"
					>
						<Typography>
							<b> Editar Perdas</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para editar os dados de uma perda, clique no botão “Perdas” na
							barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>
							2. Na área de cadastro, identifique a perda a ser editada e clique
							nos três pontinhos na lateral.
						</Typography>
						<Typography>
							3. Clique em “editar” e modifique as informações.
						</Typography>
						<Typography>
							4. Clique em “cadastrar” para salvar as modificações.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel3a-content"
						id="panel3a-header"
					>
						<Typography>
							<b>Deletar Perdas</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para deletar os dados de uma perda, clique no botão “Perdas” na
							barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>
							2. Na área de cadastro, identifique a perda a ser deletada e
							clique nos três pontinhos na lateral.
						</Typography>
						<Typography>
							3. Clique em “deletar” e confirme a operação.
						</Typography>
					</AccordionDetails>
				</Accordion>
			</>
		),
	},
	{
		identification: "lossesCad",
		title: "Nova Perda",
		help: (
			<>
				<Typography>1. Selecione o tipo de perda.</Typography>
				<Typography>
					2. Selecione a safra ou o lote onde foi identificado a perda.
				</Typography>
				<Typography>
					3. Especifique a data, quantidade perdida e descrição.
				</Typography>
				<Typography>4. Por fim, clique em” cadastrar”.</Typography>
			</>
		),
	},
	{
		identification: "lossesEdit",
		title: "Editar Perda",
		help: (
			<>
				<Typography>1. Modifique as informações.</Typography>
				<Typography>
					2. Clique em “cadastrar” para salvar as modificações.
				</Typography>
			</>
		),
	},
	//ocorrência
	{
		identification: "occurrences",
		title: "Ocorrências",
		help: (
			<>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>
							<b>Nova Ocorrência</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para cadastrar uma nova ocorrência, clique no botão
							“Ocorrências” na barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>2. Clique no botão “Nova ocorrência”.</Typography>
						<Typography>
							3. Selecione a safra, a categoria de ocorrência, o tipo de
							ocorrência e a data.
						</Typography>
						<Typography>4. Descreva a ocorrência visualizada.</Typography>
						<Typography>
							5. Faça o upload das imagens da ocorrência visualizada.
						</Typography>
						<Typography>6. Por fim, clique em” cadastrar”.</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2a-content"
						id="panel2a-header"
					>
						<Typography>
							<b>Editar Ocorrências</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para editar os dados de uma ocorrência, clique no botão
							“Ocorrência” na barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>
							2. Na área de cadastro, identifique a ocorrência a ser editada e
							clique nos três pontinhos na lateral.
						</Typography>
						<Typography>
							3. Clique em “editar” e modifique as informações.
						</Typography>
						<Typography>
							4. Clique em “cadastrar” para salvar as modificações.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel3a-content"
						id="panel3a-header"
					>
						<Typography>
							<b>Deletar Ocorrência</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para deletar os dados de uma ocorrência, clique no botão
							“Ocorrência” na barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>
							2. Na área de cadastro, identifique a ocorrência a ser deletada e
							clique nos três pontinhos na lateral.
						</Typography>
						<Typography>
							3. Clique em “deletar” e confirme a operação.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel4a-content"
						id="panel4a-header"
					>
						<Typography>
							<b>Visualizar e exportar Ocorrência</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para visualizar e exportar os dados de uma ocorrência, clique
							no botão “Ocorrência” na barra lateral para entrar no espaço de
							cadastro.
						</Typography>
						<Typography>
							2. Na área de cadastro, identifique a ocorrência a ser
							visualizada/exportada e clique nos três pontinhos na lateral.
						</Typography>
						<Typography>
							3. Clique em “exportar pdf” e visualize/exporte o relatório da
							ocorrência.
						</Typography>
					</AccordionDetails>
				</Accordion>
			</>
		),
	},
	{
		identification: "occurrencesCad",
		title: "Nova Ocorrência",
		help: (
			<>
				<Typography>
					1. Selecione a safra, a categoria de ocorrência, o tipo de ocorrência
					e a data.
				</Typography>
				<Typography>2. Descreva a ocorrência visualizada.</Typography>
				<Typography>
					3. Faça o upload das imagens da ocorrência visualizada.
				</Typography>
				<Typography>4. Por fim, clique em” cadastrar”.</Typography>
			</>
		),
	},
	{
		identification: "occurrencesEdit",
		title: "Editar Ocorrência",
		help: (
			<>
				<Typography>1. Modifique as informações.</Typography>
				<Typography>
					2. Clique em “cadastrar” para salvar as modificações.
				</Typography>
			</>
		),
	},
	//Colheitas
	{
		identification: "harvest",
		title: "Colheitas",
		help: (
			<>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>
							<b>Nova Colheita</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							Para fazer uma colheita, consideramos duas formas: A colheita via
							pedido e a colheita direta (livre).
						</Typography>
						<Typography>
							A colheita via pedido, é aquela registrada sob demanda pelo
							cliente, e possui quantidade, qualidade, produto e variedade
							definidas por ele. Essas especificações são registradas no campo
							de “pedidos” e repassado para o campo de “colheitas”.
						</Typography>
						<Typography>
							Já a colheita direta, é registrada sem demanda definida. Deve ser
							utilizada, quando não possui destinação definida e/ou quando o
							produto será estocado. Todo o processo, é feito diretamente em
							“Colheitas”.
						</Typography>
						<Typography>
							<b>Colheita via pedido:</b>
						</Typography>
						<Typography>
							1. Para cadastrar uma nova colheita, clique no botão “Colheitas”
							na barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>
							2. Na aba “Demanda”, está listado todas as demandas de colheitas
							por produto e variedade. Identifique a demanda a ser colhida e
							clique em “Colher”.
						</Typography>
						<Typography>3. Informe a data da colheita.</Typography>
						<Typography>
							4. No campo “Adicionar safras”, clique no “+” definir a safra de
							onde o produto foi colhido.
						</Typography>
						<Typography>
							5. Selecione a safra, especifique a quantidade colhida e clique em
							“Cadastrar”
						</Typography>
						<Typography>
							6. Repita o processo de adição de safras, caso a colheita seja
							feita em múltiplas safras.
						</Typography>
						<Typography>7. Por fim, clique em “cadastrar”.</Typography>
						<Typography>
							<b>Colheita direta:</b>
						</Typography>
						<Typography>
							1. Para cadastrar uma nova colheita, clique no botão “Colheitas”
							na barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>2. Clique no botão “Nova Colheita”.</Typography>
						<Typography>
							3. Informe a cultura e variedade, a embalagem de destino, a data,
							selecione o operar responsável e especifique a quantidade a ser
							colhida.
						</Typography>
						<Typography>
							4. Se a colheita irá ser registrada como demanda, clique em
							“Cadastrar e finalize a operação”.
						</Typography>
						<Typography>
							5. Se a colheita já aconteceu (Registro retroativo), clique em
							“colheita realizada”.
						</Typography>
						<Typography>
							6. No campo “Adicionar safras”, clique no “+” definir a safra de
							onde o produto foi colhido.
						</Typography>
						<Typography>
							7. Selecione a safra, especifique a quantidade colhida e clique em
							“Cadastrar”.
						</Typography>
						<Typography>
							8. Repita o processo de adição de safras, caso a colheita seja
							feita em múltiplas safras.
						</Typography>
						<Typography>9. Por fim, clique em “cadastrar”.</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2a-content"
						id="panel2a-header"
					>
						<Typography>
							<b>Editar Colheitas</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para editar os dados de uma colheita, clique no botão
							“Colheitas” na barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>
							2. Na área de cadastro, clique na aba “Colheitas Realizadas”.
						</Typography>
						<Typography>
							3. Identifique a colheita a ser editada e clique nos três
							pontinhos na lateral.
						</Typography>
						<Typography>
							4. Clique em “editar” e modifique as informações.
						</Typography>
						<Typography>
							5. Clique em “cadastrar” para salvar as modificações.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel3a-content"
						id="panel3a-header"
					>
						<Typography>
							<b>Deletar Colheitas</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para deletar os dados de uma colheita, clique no botão
							“Colheitas” na barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>
							2. Na área de cadastro, clique na aba “Colheitas Realizadas”.
						</Typography>
						<Typography>
							3. Identifique a ocorrência a ser deletada e clique nos três
							pontinhos na lateral.
						</Typography>
						<Typography>
							4. Clique em “deletar” e confirme a operação.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel4a-content"
						id="panel4a-header"
					>
						<Typography>
							<b>Registrar Previsão de Colheitas</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para registrar previsão de uma colheita, clique no botão
							“Colheitas” na barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>
							2. Na área de cadastro, clique no botão “Previsão de colheitas”.
						</Typography>
						<Typography>
							3. Selecione a safra, a data da colheita e a quantidade em
							previsão.
						</Typography>
						<Typography>4. Por fim, clique em “cadastrar”.</Typography>
					</AccordionDetails>
				</Accordion>

				<Typography>
					<b>Obs.: </b>
				</Typography>
				<Typography>
					No sistema do administrador, na área de colheitas, possui a aba
					“Colheitas Solicitadas” e “Previsões de Colheitas”. Esta aba só
					visível para o administrador, para fins de verificação de atividades
					demandadas e colheitas previstas.
				</Typography>
			</>
		),
	},
	{
		identification: "harvestCad",
		title: "Nova Ocorrência",
		help: (
			<>
				<Typography>
					Para fazer uma colheita, consideramos duas formas: A colheita via
					pedido e a colheita direta (livre).
				</Typography>
				<Typography>
					A colheita via pedido, é aquela registrada sob demanda pelo cliente, e
					possui quantidade, qualidade, produto e variedade definidas por ele.
					Essas especificações são registradas no campo de “pedidos” e repassado
					para o campo de “colheitas”.
				</Typography>
				<Typography>
					Já a colheita direta, é registrada sem demanda definida. Deve ser
					utilizada, quando não possui destinação definida e/ou quando o produto
					será estocado. Todo o processo, é feito diretamente em “Colheitas”.
				</Typography>
				<Typography>
					<b>Colheita via pedido:</b>
				</Typography>
				<Typography>
					1. Para cadastrar uma nova colheita, clique no botão “Colheitas” na
					barra lateral para entrar no espaço de cadastro.
				</Typography>
				<Typography>
					2. Na aba “Demanda”, está listado todas as demandas de colheitas por
					produto e variedade. Identifique a demanda a ser colhida e clique em
					“Colher”.
				</Typography>
				<Typography>3. Informe a data da colheita.</Typography>
				<Typography>
					4. No campo “Adicionar safras”, clique no “+” definir a safra de onde
					o produto foi colhido.
				</Typography>
				<Typography>
					5. Selecione a safra, especifique a quantidade colhida e clique em
					“Cadastrar”
				</Typography>
				<Typography>
					6. Repita o processo de adição de safras, caso a colheita seja feita
					em múltiplas safras.
				</Typography>
				<Typography>7. Por fim, clique em “cadastrar”.</Typography>
				<Typography>
					<b>Colheita direta:</b>
				</Typography>
				<Typography>
					1. Para cadastrar uma nova colheita, clique no botão “Colheitas” na
					barra lateral para entrar no espaço de cadastro.
				</Typography>
				<Typography>2. Clique no botão “Nova Colheita”.</Typography>
				<Typography>
					3. Informe a cultura e variedade, a embalagem de destino, a data,
					selecione o operar responsável e especifique a quantidade a ser
					colhida.
				</Typography>
				<Typography>
					4. Se a colheita irá ser registrada como demanda, clique em “Cadastrar
					e finalize a operação”.
				</Typography>
				<Typography>
					5. Se a colheita já aconteceu (Registro retroativo), clique em
					“colheita realizada”.
				</Typography>
				<Typography>
					6. No campo “Adicionar safras”, clique no “+” definir a safra de onde
					o produto foi colhido.
				</Typography>
				<Typography>
					7. Selecione a safra, especifique a quantidade colhida e clique em
					“Cadastrar”.
				</Typography>
				<Typography>
					8. Repita o processo de adição de safras, caso a colheita seja feita
					em múltiplas safras.
				</Typography>
				<Typography>9. Por fim, clique em “cadastrar”.</Typography>
			</>
		),
	},
	{
		identification: "harvestEdit",
		title: "Editar Ocorrência",
		help: (
			<>
				<Typography>1. Modifique as informações.</Typography>
				<Typography>
					2. Clique em “cadastrar” para salvar as modificações.
				</Typography>
			</>
		),
	},
	{
		identification: "HarvestForecast",
		title: "Registrar Previsão de Colheitas",
		help: (
			<>
				<Typography>
					1. Selecione a safra, a data da colheita e a quantidade em previsão.
				</Typography>
				<Typography>2. Por fim, clique em “cadastrar”.</Typography>
			</>
		),
	},
	//relatorio
	{
		identification: "report",
		title: "Relatórios",
		help: (
			<>
				<Typography>
					1. Para gerar um relatório, clique no botão “Relatórios” na barra
					lateral para entrar no espaço de consultas.
				</Typography>
				<Typography>
					2. Na área de relatório, no canto superior esquerdo, escolha o
					relatório a ser gerado.
				</Typography>
				<Typography>
					3. Após a escolha do relatório, filtre as variáveis e aperte “ENTER”
					no teclado do seu computador ou celular.
				</Typography>
				<Typography>4. Você irá visualizar os gráficos gerados.</Typography>
				<Typography>
					5. Para visualizar o relatório descritivo, clique em “Exportar PDF”.
				</Typography>
				<Typography>
					6. Você será direcionado para o relatório, onde poderá visualizar,
					salvar, imprimir e compartilhar.
				</Typography>
				<Typography>
					<b>Obs.:</b>
				</Typography>
				<Typography>
					Cada relatório possui suas próprias variáveis específicas. Escolha de
					acordo com sua necessidade. De acordo com sua definição, podem ser
					gerados diferentes formas de visualização dos dados.
				</Typography>
				<Typography>
					Para maior detalhamento dos relatórios,<a href="/">clique aqui.</a>
				</Typography>
			</>
		),
	},
	//estoque
	{
		identification: "stock",
		title: "Estoque",
		help: (
			<>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>
							<b>Registro de entrada de insumos no estoque</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para registrar a entrada de insumos clique no botão “Estoque”
							na barra lateral para entrar no espaço de consultas.
						</Typography>
						<Typography>
							2. Na área de estoque, clique na aba “Insumos”.
						</Typography>
						<Typography>
							3. Clique no botão “Cadastrar Entrada de Insumo”.
						</Typography>
						<Typography>4. Preencha os dados do produto.</Typography>
						<Typography>5. Anexe o receituário e a nota fiscal.</Typography>
						<Typography>
							6. Por fim, clique em “cadastrar” par salvar as informações
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2a-content"
						id="panel2a-header"
					>
						<Typography>
							<b>Editar entrada de insumos no estoque</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para editar entrada de insumos no estoque, clique no botão
							“Estoque” na barra lateral para entrar no espaço de consultas.
						</Typography>
						<Typography>
							2. Na área de cadastro, identifique a entrada a ser editada e
							clique nos três pontinhos na lateral.
						</Typography>
						<Typography>
							3. Clique em “editar” e modifique as informações.
						</Typography>
						<Typography>
							4. Clique em “cadastrar” para salvar as modificações.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel3a-content"
						id="panel3a-header"
					>
						<Typography>
							<b> Deletar entrada de insumos no estoque</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para editar entrada de insumos no estoque, clique no botão
							“Estoque” na barra lateral para entrar no espaço de consultas.
						</Typography>
						<Typography>
							2. Na área de cadastro, identifique a entrada a ser deletada e
							clique nos três pontinhos na lateral.
						</Typography>
						<Typography>
							3. Clique em “deletar” e confirme a operação.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel4a-content"
						id="panel4a-header"
					>
						<Typography>
							<b>Anexar receituário Agronômico</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para lançar o seu receituário agronômico, clique no botão
							“Estoque” na barra lateral para entrar no espaço de consultas.
						</Typography>
						<Typography>
							2. Na área de estoque, clique na aba “Insumos”.
						</Typography>
						<Typography>
							3. Clique no botão “Cadastrar Entrada de Insumo”.
						</Typography>
						<Typography>4. Clique em “Anexar receituário”.</Typography>
						<Typography>
							5. Escolha a (s) imagem (ns) na sua galeria.
						</Typography>
						<Typography>
							6. Clique em “salvar” para finalizar seu registro.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel6a-content"
						id="panel6a-header"
					>
						<Typography>
							<b>Anexar Nota Fiscal</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para lançar sua nota fiscal de compra de insumos, clique no
							botão “Estoque” na barra lateral para entrar no espaço de
							lançamentos.
						</Typography>
						<Typography>
							2. Na área de estoque, clique na aba “Insumos”.
						</Typography>
						<Typography>
							3. Clique no botão “Cadastrar Entrada de Insumo”.
						</Typography>
						<Typography>
							4. Clique em “Anexar Nota Fiscal do Insumo”.
						</Typography>
						<Typography>
							5. Escolha a (s) imagem (ns) na sua galeria.
						</Typography>
						<Typography>
							6. Clique em “salvar” para finalizar seu registro.
						</Typography>
					</AccordionDetails>
				</Accordion>
				{/* <Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel5a-content"
						id="panel5a-header"
					>
						<Typography>
							<b>Visualizar/ Exportar Registros de Entradas de Insumos</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para Visualizar/ Exportar Registros de Entradas de Insumos no
							estoque, clique no botão “Estoque” na barra lateral para entrar no
							espaço de consultas.
						</Typography>
						<Typography>
							2. Na área de cadastro, identifique a entrada a ser
							visualizada/exportada e clique nos três pontinhos na lateral.
						</Typography>
						<Typography>3. Clique em.</Typography>
					</AccordionDetails>
				</Accordion> */}
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel7a-content"
						id="panel7a-header"
					>
						<Typography>
							<b>
								Consultar estoque de produtos (Produtos colhidos, vendidos,
								perdidos e saldo)
							</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para consultar estoque de produtos, clique no botão “Estoque”
							na barra lateral para entrar no espaço de consultas.
						</Typography>
						<Typography>
							2. Na área de consultas de estoque, clique na aba “Produtos”.
						</Typography>
						<Typography>
							3. Selecione a safra, a data da colheita e a quantidade em
							previsão.
						</Typography>
						<Typography>
							3. Visualize os dados de produtos colhidos, vendidos, perdidos e
							saldo.
						</Typography>
					</AccordionDetails>
				</Accordion>
			</>
		),
	},
	{
		identification: "stockCad",
		title: "Registro de entrada de insumos no estoque",
		help: (
			<>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>
							<b>Registro de entrada de insumos no estoque</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>1. Preencha os dados do produto.</Typography>
						<Typography>2. Anexe o receituário e a nota fiscal.</Typography>
						<Typography>
							3. Por fim, clique em “cadastrar” par salvar as informações
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel4a-content"
						id="panel4a-header"
					>
						<Typography>
							<b>Anexar receituário Agronômico</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>1. Clique em “Anexar receituário”.</Typography>
						<Typography>
							2. Escolha a (s) imagem (ns) na sua galeria.
						</Typography>
						<Typography>
							3. Clique em “salvar” para finalizar seu registro.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel6a-content"
						id="panel6a-header"
					>
						<Typography>
							<b>Anexar Nota Fiscal</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Clique em “Anexar Nota Fiscal do Insumo”.
						</Typography>
						<Typography>
							2. Escolha a (s) imagem (ns) na sua galeria.
						</Typography>
						<Typography>
							3. Clique em “salvar” para finalizar seu registro.
						</Typography>
					</AccordionDetails>
				</Accordion>
			</>
		),
	},
	{
		identification: "stockEdit",
		title: "Editar entrada de insumos no estoque",
		help: (
			<>
				<Typography>1. Modifique as informações.</Typography>
				<Typography>
					2. Clique em “cadastrar” para salvar as modificações.
				</Typography>
			</>
		),
	},
	{
		identification: "HarvestForecast",
		title: "Registrar Previsão de Colheitas",
		help: (
			<>
				<Typography>
					1. Selecione a safra, a data da colheita e a quantidade em previsão.
				</Typography>
				<Typography>2. Por fim, clique em “cadastrar”.</Typography>
			</>
		),
	},
	//propriedade
	{
		identification: "property",
		title: "Propriedade",
		help: (
			<>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>
							<b>Cadastrar Propriedade</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para cadastrar uma nova propriedade clique no botão
							“Propriedade” na barra lateral para entrar no espaço de cadastro.
						</Typography>
						<Typography>2. Clique no botão “Nova Propriedade”.</Typography>
						<Typography>3. Preencha os campos solicitados.</Typography>
						<Typography>4. Clique em cadastrar.</Typography>
						<Typography>
							5. Para desenhar as delimitações da propriedade, clique nos 3
							pontos na lateral do quadro de propriedade.
						</Typography>
						<Typography>
							6. Selecione a opção editar área e vá para o mapa.
						</Typography>
						<Typography>
							7. No cursor aparecerá uma bolinha azul, sinalizando que a função
							de desenho está habilitada.
						</Typography>
						<Typography>
							8. Defina o primeiro ponto (Aresta) da propriedade.
						</Typography>
						<Typography>
							9. Em seguida, trace os próximos pontos delimitando a propriedade,
							finalizando o polígono no mesmo ponto de início.
						</Typography>
						<Typography>
							10. Pronto! As delimitações da sua propriedade estão definidas.
							Agora, defina as suas subdivisões.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2a-content"
						id="panel2a-header"
					>
						<Typography>
							<b>Cadastrar Talhão</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para cadastrar um talhão dentro da propriedade, clique nos 3
							pontinhos ao lado do nome da propriedade.
						</Typography>
						<Typography>2. Selecione a opção, “Adicionar +”.</Typography>
						<Typography>3. Selecione a opção “Talhão”.</Typography>
						<Typography>
							4. Escreva o nome da área e clique em “Salvar”.
						</Typography>
						<Typography>
							5. Leve o cursor para a área do mapa para definir a delimitação do
							talhão.
						</Typography>
						<Typography>
							6. Clique na primeira aresta da área e siga definindo a área.
						</Typography>
						<Typography>
							7. Finalize o polígono no mesmo ponto de início.
						</Typography>
						<Typography>
							8. Pronto! As delimitações do seu talhão estão definidas. Agora,
							repita o processo para as outras áreas.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel3a-content"
						id="panel3a-header"
					>
						<Typography>
							<b>Cadastrar Setor</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para cadastrar um setor dentro do Talhão, clique nos 3
							pontinhos ao lado do nome do talhão.
						</Typography>
						<Typography>2. Selecione a opção, “Adicionar +”.</Typography>
						<Typography>3. Selecione a opção “Setor”.</Typography>
						<Typography>
							4. Selecione o tipo de subdivisão, identifique a área e clique em
							“Salvar”.
						</Typography>
						<Typography>
							5. Leve o cursor para a área do mapa para definir a delimitação do
							setor.
						</Typography>
						<Typography>
							6. Clique na primeira aresta da área e siga definindo a área do
							seto.
						</Typography>
						<Typography>
							7. Finalize o polígono no mesmo ponto de início.
						</Typography>
						<Typography>
							8. Pronto! As delimitações do seu setor estão definidas. Agora,
							repita o processo para as outras áreas.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel4a-content"
						id="panel4a-header"
					>
						<Typography>
							<b>Cadastrar Parcela</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para cadastrar uma parcela dentro de uma subdivisão, clique nos
							3 pontinhos ao lado do nome da propriedade.
						</Typography>
						<Typography>2. Selecione a opção, “Adicionar +”.</Typography>
						<Typography>3. Selecione a opção “Parcela”.</Typography>
						<Typography>
							4. Selecione o termo que melhor representa sua área, cadastre o
							nome da parcela, informe se prefere desenhar um polígono ou
							definir um ponto no mapa e clique em “Salvar”.
						</Typography>
						<Typography>
							5. Leve o cursor para a área do mapa para definir a delimitação ou
							o ponto da parcela.
						</Typography>
						<Typography>
							6. Se for área (polígono), clique na primeira aresta da área e
							siga definindo a área da parcela; Finalize o polígono no mesmo
							ponto de início.
						</Typography>
						<Typography>
							7. Se for ponto, apenas clique na tela e marque sua parcela.
						</Typography>
						<Typography>
							8. Pronto! A delimitação da sua parcela está definida. Agora,
							repita o processo para as outras áreas.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel5a-content"
						id="panel5a-header"
					>
						<Typography>
							<b>Cadastrar Reserva Legal</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para cadastrar uma Reserva Legal, clique nos 3 pontinhos ao
							lado do nome da propriedade.
						</Typography>
						<Typography>2. Selecione a opção, “Adicionar +”.</Typography>
						<Typography>3. Selecione a opção “Reserva Legal”.</Typography>
						<Typography>
							4. Leve o cursor para a área do mapa para definir a delimitação da
							Reserva Legal.
						</Typography>
						<Typography>
							5. Clique na primeira aresta da área e siga definindo a área da
							reserva.
						</Typography>
						<Typography>
							6. Finalize o polígono no mesmo ponto de início.
						</Typography>
						<Typography>
							7. Pronto! As delimitações da sua reserva estão definidas. Agora,
							repita o processo para as outras áreas.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel6a-content"
						id="panel6a-header"
					>
						<Typography>
							<b>Cadastrar Corpos Hídricos</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para cadastrar um Corpo Hídrico, clique nos 3 pontinhos ao lado
							do nome da propriedade, talhão ou setor.
						</Typography>
						<Typography>2. Selecione a opção, “Adicionar +”.</Typography>
						<Typography>3. Selecione a opção “Corpo Hídrico”.</Typography>
						<Typography>
							4. Leve o cursor para a área do mapa para definir a delimitação do
							Corpo Hídrico.
						</Typography>
						<Typography>
							5. Clique no primeiro e siga definindo a linha do corpo.
						</Typography>
						<Typography>
							6. Finalize a definição clicando duas vezes no último ponto.
						</Typography>
						<Typography>
							7. Pronto! A delimitação do corpo hídrico está definida.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel7a-content"
						id="panel7a-header"
					>
						<Typography>
							<b>Cadastrar Reservatório</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para cadastrar um Reservatório, clique nos 3 pontinhos ao lado
							do nome da propriedade, talhão ou setor.
						</Typography>
						<Typography>2. Selecione a opção, “Adicionar +”.</Typography>
						<Typography>3. Selecione a opção “Reservatório”.</Typography>
						<Typography>
							4. Leve o cursor para a área do mapa para definir o ponto.
						</Typography>
						<Typography>
							5. Clique no ponto onde o reservatório está situado.
						</Typography>
						<Typography>
							6. Pronto! O ponto do seu reservatório está definido.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel8a-content"
						id="panel8a-header"
					>
						<Typography>
							<b>Cadastrar Sede</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para cadastrar a sede, clique nos 3 pontinhos ao lado do nome
							da propriedade, talhão ou setor.
						</Typography>
						<Typography>2. Selecione a opção, “Adicionar +”.</Typography>
						<Typography>3. Selecione a opção “Sede”.</Typography>
						<Typography>
							4. Leve o cursor para a área do mapa para definir o ponto.
						</Typography>
						<Typography>
							5. Clique no ponto onde a sede está situada.
						</Typography>
						<Typography>
							6. Pronto! O ponto da sua sede está definido.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel9a-content"
						id="panel9a-header"
					>
						<Typography>
							<b>Editar as áreas e pontuações do mapa</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para editar o mapa, clique nos 3 pontinhos ao lado do nome da
							área que quer editar (propriedade, talhão, setor, reserva legal,
							sede ou reservatório).
						</Typography>
						<Typography>2. Selecione a opção, “Editar área”.</Typography>
						<Typography>
							3. Leve o cursor para a área do mapa para definir a delimitação ou
							o ponto.
						</Typography>
						<Typography>
							4. Se for área (polígono), clique na primeira aresta da área e
							siga definindo a área da parcela; finalize o polígono no mesmo
							ponto de início.
						</Typography>
						<Typography>
							5. Se for ponto, apenas clique na tela e marque sua parcela.
						</Typography>
						<Typography>6. Pronto! Seu mapa está atualizado.</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel10a-content"
						id="panel10a-header"
					>
						<Typography>
							<b>Editar os nomes das áreas e pontuações do mapa</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para editar o mapa, clique nos 3 pontinhos ao lado do nome da
							área que quer editar (propriedade, talhão, setor, reserva legal,
							sede ou reservatório).
						</Typography>
						<Typography>2. Selecione a opção, “Editar nome”.</Typography>
						<Typography>3. Renomeie e clique em “salvar”.</Typography>
					</AccordionDetails>
				</Accordion>

				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel11a-content"
						id="panel11a-header"
					>
						<Typography>
							<b>Excluir áreas e pontuações do mapa</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para editar o mapa, clique nos 3 pontinhos ao lado do nome da
							área que quer deletar (propriedade, talhão, setor, reserva legal,
							sede ou reservatório).
						</Typography>
						<Typography>2. Selecione a opção, “Excluir”.</Typography>
						<Typography>3. Pronto! Sua área está excluída.</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel12a-content"
						id="panel12a-header"
					>
						<Typography>
							<b>Gerar QR Code da área</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							1. Para Gerar Qr code de identificação da área (Talhão e/setor),
							clique nos 3 pontinhos ao lado do nome da área
						</Typography>
						<Typography>2. Selecione a opção, “Gerar Qr Code”.</Typography>
						<Typography>3. Escolher entrar opção de placa.</Typography>
						<Typography>
							4. Exporte o modelo que selecionou e identifique sua área.
						</Typography>
					</AccordionDetails>
				</Accordion>
			</>
		),
	},
];
